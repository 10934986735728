<template>
    <section class="home-hero_section">
        <div class="container-medium">
                <div class="is-home-hero">
                    <div class="home-hero_layout">
                        <h1 js-line-animation class="text-size-tiny text-color-violet text-style-allcaps text-weight-bold" style="opacity: 0; visibility: inherit; font-weight: 500;">
                            <span class="line">
                                creative webdesign agency in Suffolk, England
                            </span>
                        </h1>
                        <p 
                            js-line-animation
                            class="heading-style-h1 is-home-hero"
                            style="opacity: 0; visibility: inherit;"
                        >
                            <span class="line">{{ line1 }}</span>
                            <span class="scramble line">{{ line2 }}</span>
                            <span class="line" v-if="line3">{{ line3 }}</span>
                            <span class="line" v-if="line4">{{ line4 }}</span>
                        </p>
                        <StartButton v-if="!noButton" />
                    </div>
                </div>
            </div>
    </section>
</template>

<script>

    import gsap from 'gsap';
    import $ from 'jquery';

    import StartButton from '@/components/StartButton.vue'
    import TextPlugin from '@/assets/js/TextPlugin.min.js';
    gsap.registerPlugin(TextPlugin);


    export default {
        name: 'AnimatedHomeHero',
        props: {
            line1: String,
            line2: String,
            line3: String,
            line4: String,
            noButton: Boolean
        },
        data() {
            return {
                running: true,
                count: 0
            }
        },
        components: {
            StartButton
        },
        unmounted() {
            console.log("unmounted");
            this.running = false;
        },
        methods: {
            scrambleIt: function() {
                var words = [
                    "creative",
                    "intuitive",
                    "stunning",
                    "memorable",
                    "responsive",
                    "modern",
                    "fun",
                    "engaging",
                    "beautiful",
                    "interactive",
                    "fast",
                ];
                if (!this.running) return;
                if (this.count < words.length) {
                    gsap.to(".scramble", 1,  {text: words[this.count], delay:2, onComplete: this.scrambleIt });
                    this.count++;
                    this.count %= words.length;
                }
            }
        },
        mounted() {

            if (this.running && $(".scrabble")) this.scrambleIt();

            // Services each section
            $(".home-hero_section").each(function (index) {
            
                let heroButton = $(this).find(".button.is-hero");
                let heroSub = $(this).find(".text-size-tiny");
            
                let tl = gsap.timeline({ delay: 1 });
                tl.to(heroButton, {
                    opacity: 1,
                    duration: 1,
                    ease: "power4.out"
                }).to(
                    heroSub,
                    {
                        opacity: 1,
                        duration: 1,
                        ease: "power4.out"
                    },
                    "<"
                );
            });
        }
    }
</script>

<style scoped>

    .home-hero_section {
        position: relative;
        padding: 0 20px;
    }
    .home-hero_layout {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-column-gap: 1.5em;
        grid-row-gap: 1.5em;
        padding-top: 98px;
        padding-bottom: 80px;
    }

</style>