<template>
    <a href="https://calendly.com/kasper-design/30min" target="_blank" class="button is-hero w-inline-block" style="opacity: 1;">
        <div class="btn_text-wrap">
            <div class="text_btn is-1">
                <span class="word"><span class="char">b</span>
                    <span class="char">o</span>
                    <span class="char">o</span>
                    <span class="char">k</span>
                </span> 
                <span class="word">
                    <span class="char">c</span>
                    <span class="char">a</span>
                    <span class="char">l</span>
                    <span class="char">l</span>
                </span>
            </div>
            <div class="text_btn is-2">
                <span class="word">
                    <span class="char char-translate">b</span>
                    <span class="char char-translate">o</span>
                    <span class="char char-translate">o</span>
                    <span class="char char-translate">k</span>
                </span> 
                <span class="word">
                    <span class="char char-translate">c</span>
                    <span class="char char-translate">a</span>
                    <span class="char char-translate">l</span>
                    <span class="char char-translate">l</span>
                </span>
            </div>
        </div>
    </a>
</template>


<script>

import gsap from 'gsap';
import $ from 'jquery';
import splittype from '@/assets/js/splittype.min.js';

gsap.registerPlugin(splittype);

export default {
    name: 'StartButton',
    methods: {
        buttonAnimation: function() {
            $(".button").each(function (index) {
            let listOne = $(this).find(".text_btn.is-1 .char");
            let listTwo = $(this).find(".text_btn.is-2 .char");
            // Timeline
            let buttonHover = gsap.timeline({ paused: true });
            buttonHover.to(listOne, {
                translateY: "-2rem",
                stagger: { each: 0.02 },
                ease: "power3.inOut",
                duration: 0.4
            });
            buttonHover.from(
                listTwo,
                {
                translateY: "2rem",
                stagger: { each: 0.02 },
                ease: "power3.inOut",
                duration: 0.4
                },
                0.1
            );
            $(this).on("mouseenter", function () {
                buttonHover.restart();
            });
            $(this).on("mouseleave", function () {
                buttonHover.reverse();
            });
            });
        },
    },
    mounted() {
        this.buttonAnimation();
    }
}
</script>



<style scoped>

  .services {
    min-height: 600px;
  }

</style>