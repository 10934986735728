<template>
  <div class="quick-contact">
    <h1>Get in touch</h1>
    <div class="send-confirmation" v-if="sent">
        <div v-if="success">
          Thank you for your message. We will be in touch shortly.
        </div>
        <div v-else>
          Something went wrong...
        </div>
    </div>

    <form @submit.prevent class="form" v-show="!sent">
        
      <input v-model="name" type="text" placeholder="NAME">
      <input v-model="email" type="text" placeholder="E-MAIL">
      <textarea v-model="message" type="text" placeholder="MESSAGE"></textarea>
      <SendButton v-on:click="formSubmit" />
      
    </form>
  </div>
</template>

<script>

  import axios from 'axios';
  import SendButton from '@/components/SendButton.vue'
   
  export default {
    name: 'QuickContact',
    components: {
      SendButton
    },
    data: () => ({
      sent: false,
      success: false,
      name: '',
      email: '',
      message: ''
    }),
    methods: {
      formSubmit() {
        console.log('form submitted', this.name, this.email, this.message);

        axios.post('https://rkerwvm5xg.execute-api.eu-west-2.amazonaws.com/default/contact', {
          name: this.name,
          email: this.email,
          message: this.message
        })
        .then((response) => {
          console.log(response);
          this.sent = true;
          this.success = true;
        })
        .catch((error) => {
          console.log(error);
          this.sent = true;
          this.success = false;
        });
      }
    }
  }
</script>

<style lang="scss">

  input,
  textarea {
    margin: 10px auto;
    padding: 10px 20px;
  }

  textarea {
    height: 180px;
    padding: 16px 20px;
  }

  .quick-contact {
    padding: 40px;
    background-color: rgb(22, 22, 23);
    min-height: 600px;
    position: relative;
    z-index: 99999;
  }

  .quick-contact h1 {
    color: #333;
  }

  .contact-header {
    position: relative;
    margin: 100px 0 25px 0;
    font-size: 2.3em;
    text-align: center;
    letter-spacing: 7px;
    color: #ccc;
    font-weight: 300;
  }

  .form {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 50px auto 100px auto;
  }

  .send-confirmation {
    z-index: 99;
    color: #fff;
    position: relative;
    padding: 0 40px;
    text-align: center;
  }

</style>