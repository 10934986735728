<template>
  <Vue3Marquee>
    <h2
      v-for="(word, index) in helloArray"
      :key="index"
      class="word-style"
      :class="{ word: true }"
    >
      {{ word }}
    </h2>
  </Vue3Marquee>
</template>

<script>
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'

export default {
  name: "TextMarquee",
  components: {
    Vue3Marquee,
  },
  data() {
    return {
      helloArray: [
        "User Experience",
        "WebGL",
        "Animation",
        "AI",
        "Apps",
        "Web Design",
        "UX / UI",
        "Wordpress",
        "WebAssembly",
        "SEO"
      ],
    };
  },
};
</script>

<style scoped>
    .word {
        font-size: 30px;
        margin: 0 20px;
        color: rgb(234, 234, 234);
        letter-spacing: 4px;
    }
    .vue3-marquee {
      height: 60px;
    }

</style>
