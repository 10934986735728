<template>
   <main class="landing-page" style="padding: 0">

      <AnimatedHomeHero 
         line1="We code"
         line2="creative"
         line3="apps and"
         line4="websites"
      />

      <section class="home-about_section" style="display: none;">

         <div style="padding: 40px 0;">
            <TextMarquee clone />
         </div>

         <div class="padding-global" >
            <div class="container-small">
               <div class="padding-section-small">
                  <div class="home-about_layout">
                     <div class="home-about_content">
                        <h2 js-line-animation class="text-color-pink text-weight-bold text-style-allcaps" style="opacity: 0; visibility: inherit;">
                              About us
                        </h2>
                        <p js-line-animation class="heading-style-h3 is-home-about" style="opacity: 0; visibility: inherit;">
                           <span class="line">We are kasper.design. A web design agency</span>
                           <span class="line">where creativity meets functionality,</span>
                           <span class="line">and innovation meets excellence! Our mission</span>
                           <span class="line">is to help businesses of all shapes and sizes</span>
                           <span class="line">achieve their goals through dynamic,</span>
                           <span class="line">cutting-edge web design.</span>
                        </p>
                        <a href="/contact" class="home-about_link w-inline-block">
                           <div>Get in touch</div>
                           <div class="navigation_link-dash-wrap">
                              <div class="navigation_link-dash background-color-yellow is-mobile"></div>
                           </div>
                        </a>
                     </div>
                     <!--<CodeScrollType />-->
                  </div>
               </div>
            </div>
         </div>
      </section>
   
      <section class="home-work_section">
         <div class="padding-global" style="max-width: 1400px; margin: auto;">
            <div class="container">
               <div class="padding-section-medium is-work">
                  <div class="home-work_layout">
                     <h2 js-line-animation class="text-color-violet text-weight-bold" style="opacity: 0; visibility: inherit;">Past Work</h2>
                     <div class="work-grid_wrapper w-dyn-list">
                        <div role="list" class="work-grid_list w-dyn-items">
                           <div class="work-grid_item w-dyn-item" data-aos="fade-up" data-aos-delay="200">
                              <a href="https://digit-recognition.kasper.design/" target="_blank" class="work-grid_link w-inline-block">
                                 <img src="@/assets/img/stare2.png" loading="eager" alt="AI Stare" class="work-grid_img">
                                 <img src="@/assets/img/computer_girl.jpg" loading="eager" alt="AI girl" class="work-grid_img mouseoverinvisible">
                              
                              </a>
                              <div class="work-grid_info-wrap">
                                 <h3 class="heading-style-h4">AI</h3>
                                 <p class="text-color-grey">Neural Networks</p>
                              </div>
                           </div>
                           <div class="work-grid_item w-dyn-item" data-aos="fade-up">
                              <a href="https://thebloomlounge.co.uk" target="_blank" class="work-grid_link w-inline-block"><img src="@/assets/img/bloom.gif" loading="eager" alt="The Bloon Lounge Ipswich" class="work-grid_img"></a>
                              <div class="work-grid_info-wrap">
                                 <h3 class="heading-style-h4">The Bloom Lounge</h3>
                                 <p class="text-color-grey">Back to fresh and clean beauty</p>
                              </div>
                           </div>

                           <div class="work-grid_item w-dyn-item" data-aos="fade-up" data-aos-delay="200">
                              <a href="https://jba-landmarc.com" target="_blank" class="work-grid_link w-inline-block">
                                 <img src="@/assets/img/jba-alt.png" loading="eager" alt="Landscape architects" class="work-grid_img">
                                 <img src="@/assets/img/jba.png" loading="eager" alt="AI girl" class="work-grid_img mouseoverinvisible">
                              </a>
                              <div class="work-grid_info-wrap">
                                 <h3 class="heading-style-h4">JBA</h3>
                                 <p class="text-color-grey">Landscape architects</p>
                              </div>
                           </div>
                           <div class="work-grid_item w-dyn-item" data-aos="fade-up">
                              <a href="https://d1znpftvx8sbgy.cloudfront.net/" target="_blank" class="work-grid_link w-inline-block"><img src="@/assets/img/dripdrop.png" loading="eager" alt="DripDrop" class="work-grid_img"></a>
                              <div class="work-grid_info-wrap">
                                 <h3 class="heading-style-h4">Drip Drop</h3>
                                 <p class="text-color-grey">Logistics solution</p>
                              </div>
                           </div>

                           <div class="work-grid_item w-dyn-item" data-aos="fade-up" data-aos-delay="200">
                              <a href="https://amberlink.co.uk" target="_blank" class="work-grid_link w-inline-block"><img src="@/assets/img/amberlink.png" loading="eager" alt="Amberlink" class="work-grid_img"></a>
                              <div class="work-grid_info-wrap">
                                 <h3 class="heading-style-h4">Amberlink</h3>
                                 <p class="text-color-grey">Property Management</p>
                              </div>
                           </div>
                           <div class="work-grid_item w-dyn-item" data-aos="fade-up" data-aos-delay="200">
                              <a href="https://mango-media.eu" target="_blank" class="work-grid_link w-inline-block"><img src="@/assets/img/mango-media.gif" loading="eager" alt="Mango Media" class="work-grid_img"></a>
                              <div class="work-grid_info-wrap">
                                 <h3 class="heading-style-h4">Mango Media</h3>
                                 <p class="text-color-grey">Web Design Agency</p>
                              </div>
                           </div>

                           <div class="work-grid_item w-dyn-item" data-aos="fade-up">
                              <a href="http://menacing.media.s3-website.eu-west-2.amazonaws.com/" class="work-grid_link w-inline-block" target="_blank">
                                 <img src="@/assets/img/MenacingMedia.gif" loading="eager" alt="Digital marketing firm" class="work-grid_img">
                              </a>
                              <div class="work-grid_info-wrap">
                                 <h3 class="heading-style-h4">Menacing Media</h3>
                                 <p class="text-color-grey">Digital Marketing Agency</p>
                              </div>
                           </div>
                           <div class="work-grid_item w-dyn-item" data-aos="fade-up">
                              <a href="https://dev.kasper.design/LoveYourStays/" class="work-grid_link w-inline-block" target="_blank">
                                 <img src="@/assets/img/love-your-stays.png" loading="eager" alt="Love Your Stays" class="work-grid_img">
                              </a>
                              <div class="work-grid_info-wrap">
                                 <h3 class="heading-style-h4">Love Your Stays</h3>
                                 <p class="text-color-grey">Holiday Rentals</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section page-color="trigger" class="home-cta_section">
         <div class="padding-global">
            <div class="container">
               <div class="padding-section-medium">
                  <div class="home-cta_layout">
                     <h2 cta-sub="" class="text-color-violet text-weight-bold text-style-allcaps is-cta scroll-active">elevate your brand</h2>
                     <p cta-text="" class="heading-style-display is-cta" style="mix-blend-mode: difference;">
                        book a free <br>discovery call</p>
                        <BookCall />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!--
      <section style="padding: 40px 0;">
         <Testimonials pauseOnHover clone direction="reverse" />
      </section>
      -->
      <section style="background-color: #161617">
         <div style="padding: 40px 0 180px;">
            <TextMarquee clone />
         </div>
      </section>

      <QuickContact />

   </main>
</template>

<script>

   import gsap from 'gsap';
   import $ from 'jquery';

   import AnimatedHomeHero from '@/components/AnimatedHomeHero.vue'
   import CodeScrollType from '@/components/CodeScrollType.vue'
   import TextMarquee from '@/components/TextMarquee.vue'
   import Testimonials from '@/components/Testimonials.vue'
   import StartButton from '@/components/StartButton.vue'
   import BookCall from '@/components/BookCallButton.vue'
   import QuickContact from '@/components/QuickContact.vue'
   
   import scrolltrigger from '@/assets/js/ScrollTrigger.min.js';

   gsap.registerPlugin(scrolltrigger);

   export default {
      name: 'HomeView',
      components: {
         AnimatedHomeHero,
         CodeScrollType,
         StartButton,
         BookCall,
         QuickContact,
         TextMarquee,
         Testimonials
      },
      methods: {
         flipBackground: function() {
            // attribute value checker
            function attr(defaultVal, attrVal) {
               const defaultValType = typeof defaultVal;
               if (typeof attrVal !== "string" || attrVal.trim() === "") return defaultVal;
               if (attrVal === "true" && defaultValType === "boolean") return true;
               if (attrVal === "false" && defaultValType === "boolean") return false;
               if (isNaN(attrVal) && defaultValType === "string") return attrVal;
               if (!isNaN(attrVal) && defaultValType === "number") return +attrVal;
               return defaultVal;
            }

            let mm = gsap.matchMedia();
            // on desktop
            mm.add("(min-width: 992px)", () => {
               $("[page-color='trigger']").each(function () {
                  // elements
                  let triggerEl = $(this);
                  // settings
                  let classSetting = attr("dark-mode", triggerEl.attr("tr-pagecolor-class"));
                  // result
                  scrolltrigger.create({
                     trigger: triggerEl,
                     start: "top 70%",
                     end: "bottom 30%",
                     markers: false,
                     onToggle: ({ self, isActive }) => {
                        if (window.location.pathname != "/") return;
                        if (isActive) {
                           $("#app").addClass(classSetting);
                        } else {
                           $("#app").removeClass(classSetting);
                        }
                     }
                  });
               });
            });
         }
      },
      mounted() {

         AOS.init();

         this.flipBackground();    
   }
   }
</script>
