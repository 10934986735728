<template>
  <!-- Site footer -->
    <footer class="site-footer">

          <div class="footer-text">

            <router-link to="/" class="navigation_link-brand w-inline-block" style="margin-bottom: 20px; filter: invert(0.9)">
              <img src="@/assets/img/text-only-transparent.png" width="600" alt="Kasper Design Ltd.">
            </router-link>

            <p style="margin-bottom: 20px; font-size: 1.4em;">Kasper Design Ltd. Where innovation meets excellence.</p>
            <p style="margin-bottom: 40px; font-size: 1.4em;">Elevate your online presence and dominate the digital world with us.<br><br>🏅 🏆 👑 🚀</p>

            <div>
              <h3 style="color: #fff;">
                <i class="fa fa-envelope"></i> &nbsp; <a href="mailto:hello@kasper.design">hello@kasper.design</a>
              </h3><br>
              <h3 style="color: #fff;">
                <i class="fa fa-envelope"></i> &nbsp; <a href="mailto:hello@kasperdesign.com">hello@kasperdesign.com</a>
              </h3>
            </div>

          </div>
          <div class="footer-layout">
            <div class="footer_navigation-links">
              <h3 style="color: #fff;">
                <router-link to="/" aria-current="page" class="navigation-link is-footer w-inline-block w--current nav-active">
                  <div class="navigation-link-text">home</div>
                </router-link> &nbsp;
                <router-link to="/services" class="navigation-link is-footer w-inline-block">
                  <div class="navigation-link-text">services</div>
                </router-link> &nbsp;
                <a href="https://portfolio.kasper.design" target="_blank" class="navigation-link is-footer w-inline-block">
                  <div class="navigation-link-text">work</div>
                </a> &nbsp;
                <router-link to="/contact" class="navigation-link is-footer w-inline-block">
                  <div class="navigation-link-text">contact</div>
                </router-link>
              </h3>
            </div>
            <div style="width: 100%">

              <h2>Call us free today:</h2><br>
              <h1><a href="tel:08002922048">0800 292 2048</a></h1><br><br>

              <BookCallButton />
            </div>
          </div>

          <div style="width: 100%; text-align: center; padding: 40px 20px 0;">

          </div>

        <hr>

        <div class="row bottom-footer-text">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">© 2024. All rights reserved.<br><router-link to="/privacy-policy">Privacy Policy</router-link> | <router-link to="/terms-and-conditions">Terms &amp; Conditions</router-link> |  <router-link to="/GDPR">GDPR Policy</router-link>
            </p><br><br>
              <p>Made with ❤️ by <b class="text-color-violet">kasper.design</b></p>
         
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12">
            <ul class="social-icons">
              <li><a class="facebook" href="https://www.facebook.com/profile.php?id=61560449106899" target="_blank"><i class="fa fa-facebook"></i></a></li>
              <li><a class="twitter" href="https://x.com/kasper_design" target="_blank"><i class="fa fa-twitter"></i></a></li>
              <li><a class="mail" href="mailto:hello@kasper.design"><i class="fa fa-envelope"></i></a></li>
              <li><a class="insta" href="https://www.instagram.com/kasper_design_ltd" target="_blank"><i class="fa fa-instagram"></i></a></li>
              <li><a class="whatsapp" href="https://wa.me/message/AT5DX4FC3F63H1" target="_blank"><i class="fa fa-whatsapp"></i></a></li>
              <li><a class="github" href="https://github.com/KasperDesign/" target="_blank"><i class="fa fa-github"></i></a></li>
              <li><a class="linkedin" href="https://www.linkedin.com/company/kasper-design/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
</footer>
</template>

<script>
  import BookCallButton from '@/components/BookCallButton.vue'
  export default {
    name: 'Footer',
    components: {
      BookCallButton
    }
  }
</script>

<style scoped lang="scss">

footer {
  z-index: 99999;
  position: relative;
}

p {
  text-align: left;
}

a:hover {
  color: #D90368;
}

.footer_navigation-links {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  grid-column-gap: 3.47em;
  padding-bottom: 100px;
}

.site-footer {
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
  overflow-x: hidden;
}

.footer-text {
  padding-right: 40px;
  text-align: justify;
}

.footer-layout,
.footer-text {
  max-width: 50%;
  width: 100%;
  display: inline-block;
}

.footer-copy {
  max-width: 100%;
  font-size: 1.4em;
}

@media (max-width:1000px) {
  .footer-layout,
  .footer-text {
    max-width: 100%;
    display: block;
  }

  .footer_navigation-links {
    margin-top: 40px;
  }

  .bottom-footer-text {
    padding-top: 80px;
  }
}


.footer-layout {
  height: 200px;
  vertical-align: top;
}

.site-footer hr {
  border-top-color:#bbb;
  opacity:0.5;
  width: calc(100% - 2px);
  position: absolute;
  left: 0;
}

.site-footer h6 {
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}

.site-footer .social-icons {
  text-align:right;
  margin-top: -40px;
}
.site-footer .social-icons a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
  font-size: 1.8em;
}

@media (max-width:600px) {
  .site-footer .social-icons a {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}
@media (max-width:480px) {
  .site-footer .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
@media (max-width:420px) {
  .site-footer .social-icons a {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
}

.copyright-text {
  margin:0;
  padding-top: 80px;
}
@media (max-width:991px) {
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px) {
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons {
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li {
  display:inline-block;
  margin-bottom:4px
}

.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover {
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a {
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover {
  background-color:#3b5998
}
.social-icons a.twitter:hover {
  background-color:#00aced
}
.social-icons a.linkedin:hover {
  background-color:#007bb6
}
.social-icons a.mail:hover {
  background-color:#D90368
}
.social-icons a.whatsapp:hover {
  background-color:#28D146
}
.social-icons a.insta:hover {
  background-color:#E1306C
}

.site-footer {
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width:1100px) {
  .site-footer .social-icons {
    margin-top: 20px;
  }
}

</style>
