<template>
    <div>
        <Header />
        <div class="reveal-content">
            <router-view/>
        </div>
        <Footer />
    </div>
</template>

<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

import gsap from 'gsap';
import $ from 'jquery';
import splittype from '@/assets/js/splittype.min.js';

gsap.registerPlugin(splittype);

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  watch:{
        $route (to, from){
            this.lineAnimation();
            $('html,body').animate({ scrollTop: 0 }, 'slow');
            $("#app").removeClass("dark-mode")

            return false; 
        }
  },
  methods: {
    lineAnimation: function() {
        if (!$("[js-line-animation]")) return;
        setTimeout(() => {
            $("[js-line-animation]").each(function (index) {
                if (!$(this)) return;
                gsap.set($(this), { autoAlpha: 1 });
                let textEl = $(this);
                let textContent = $(this).text();
                let tl;
        
                function splitText() {
                    new splittype(textEl, { types: "lines", tagName: "span" });
                    textEl.find(".line").each(function (index) {
                        let lineContent = $(this).html();
                        $(this).html("");
                        $(this).append(
                        `<span class="line-inner" style="display: block;">${lineContent}</span>`
                        );
                    });
                    tl = gsap.timeline({
                        scrollTrigger: {
                        trigger: textEl,
                        start: "top 70%",
                        end: "bottom bottom"
                        }
                    });
                    tl.from(
                        textEl.find(".line-inner"),
                        { yPercent: 100 },
                        { opacity: 0 },
                        { rotation: 30 },
                        {
                            yPercent: 0,
                            duration: 1,
                            stagger: { amount: 0.3, ease: "power4.out" }
                        }
                    );
                }
                splitText();
        
                let windowWidth = window.innerWidth;
                window.addEventListener("resize", function () {
                    if (windowWidth !== window.innerWidth) {
                        windowWidth = window.innerWidth;
                        tl.kill();
                        textEl.text(textContent);
                        splitText();
                    }
                });
            });
        }, 500);
    }
  },
  mounted() {

  this.lineAnimation();





var FPS = 40;
var CONFIG = {
    SIM_RESOLUTION: 128,
    DYE_RESOLUTION: 512,
    DENSITY_DISSIPATION: .98,
    VELOCITY_DISSIPATION: .98,
    PRESSURE_DISSIPATION: .98,
    PRESSURE_ITERATIONS: 8,
    CURL: 8,
    SPLAT_RADIUS: 4,
    SHADING: false,
    COLORFUL: true,
    BACK_COLOR: { r: 228, g: 228, b: 228 },
    BACK_COLOR_ALT: { r: 12, g: 12, b: 12 },
    TRANSPARENT: false,
    BLOOM: false,
    BLOOM_ITERATIONS: 0,
    BLOOM_RESOLUTION: 128,
    BLOOM_INTENSITY: .1,
    BLOOM_THRESHOLD: .9,
    BLOOM_SOFT_KNEE: .9
};
var col = { r: 234, g: 234, b: 234 };
! function(t) {
    var n = {},
        i = { 6: 0 };

    function o(e) {
        if (n[e]) return n[e].exports;
        var r = n[e] = { i: e, l: false, exports: {} };
        return t[e].call(r.exports, r, r.exports, o), r.l = true, r.exports
    } 
    o.e = function(t) {
        var e = [];

        var n = i[t];
        if (0 !== n)
            if (n) e.push(n[2]);
            else {
                var s = new Promise((function(e, r) { n = i[t] = [e, r] }));
                e.push(n[2] = s);
            } return Promise.all(e)
    },
    o.m = t,
    o.c = n,
    o.d = function(t, e, n) { o.o(t, e) || Object.defineProperty(t, e, { get: n }) },
    o.r = function(t) {
        "undefined" != typeof Symbol && Symbol.toStringTag, Object.defineProperty(t, "__esModule", { value: true }) 
    },
    o.n = function(t) {
        var e = t && t.__esModule ? function() { return t.default } : function() { return t }; return o.d(e, "a", e), e }, 
    o.o = function(t, e) { return Object.prototype.hasOwnProperty.call(t, e) }, 
    o.p = "/";
    o(o.s = 17)
}([
function(t, e, n) { // 0
    "use strict";

    var r = l(n(8)),
        i = l(n(2));

    function l(t) { return t && t.__esModule ? t : { default: t } }

    function h(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }
    var page = function() {

        function t() {
            var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
            this.prop = Object.assign({}, e), this._init()
        }
        var e, n;
        return e = t, n = [{
            key: "html",
            get: function() { return document.documentElement } 
        }, {
            key: "_init",
            value: function() {
                this.load = new r.default({ v: this }),
                this.viewport = new i.default({ v: this }),
                this.customPages = [],
                window.customApplication = this
            }
        }], n && h(e.prototype, n), t
    }();

    e.a = new(n.n(page).a);
}, function(t, e, n) { // 1
    "use strict";

    function a(t, e = {}) {
        const n = document.createElement(t);
        if (e.parent && e.parent.appendChild(n), e.html && (n.innerHTML = e.html), e.children)
            for (let r = 0, i = e.children.length; r < i; r++) n.appendChild(e.children[r]);
        return n
    }
    n.d(e, "g", (function() { return i })),
    n.d(e, "b", (function() { return a }));
}, function(t, e, n) { // 2
    "use strict";

    function r(t) { return r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(t) { return typeof t } : function(t) { return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t }, r(t) } Object.defineProperty(e, "__esModule", { value: true }), e.default = void 0;
    var i = s(n(9));

    function s(t) { return t && t.__esModule ? t : { default: t } }

    function a(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }

    function c(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    function u(t, e) { return u = Object.setPrototypeOf || function(t, e) { return t.__proto__ = e, t }, u(t, e) }

    function h(t) {
        var e = function() { if ("undefined" == typeof Reflect || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if ("function" == typeof Proxy) return true; try { return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (function() {}))), true } catch (t) { return false } }();
        return function() {
            var n, r = d(t);
            if (e) {
                var i = d(this).constructor;
                n = Reflect.construct(r, arguments, i)
            } else n = r.apply(this, arguments);
            return f(this, n)
        }
    }

    function f(t, e) { return !e || "object" !== r(e) && "function" != typeof e ? function(t) { if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return t }(t) : e }

    function d(t) { return d = Object.setPrototypeOf ? Object.getPrototypeOf : function(t) { return t.__proto__ || Object.getPrototypeOf(t) }, d(t) }
    var p = function(t) {
        ! function(t, e) {
            if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
            t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: true, configurable: true } }), e && u(t, e)
        }(s, t);
        var e, n, r, i = h(s);

        function s() { return a(this, s), i.apply(this, arguments) }
        return e = s, (n = [
            { key: "_extra", value: function() { } 
            }, { key: "size", get: function() { return this._size } 
            }, { key: "_setEvents", value: function() { window.addEventListener("resize", this._set.bind(this)), this._set() }
            }, {
            key: "_set",
            value: function() {
                var t = this._v.html;
                this._size = [t.clientWidth, t.clientHeight];
            }
        }]) && c(e.prototype, n), r && c(e, r), s
    }(i.default);
    e.default = p
}, function(t, e, n) { // 3 // custom element
    "use strict";
    n.d(e, "b", (function() { return J })),
    n.d(e, "a", (function() { return Z }));

    window.JSCompiler_renameProperty = t => t;
    const X = (t, e) => e !== t && (e == e || t == t),
        Y = "finalized";
    class Z extends HTMLElement {
        constructor() { super(), this.initialize() } static get observedAttributes() {
            this.finalize();
            const t = [];
            return this._classProperties.forEach(((e, n) => {
                const r = this._attributeNameForProperty(n, e);
                void 0 !== r && (this._attributeToPropertyMap.set(r, n), t.push(r))
            })), t
        }
        static _ensureClassProperties() {
            if (!this.hasOwnProperty(JSCompiler_renameProperty("_classProperties", this))) {
                this._classProperties = new Map;
            }
        }

        static finalize() {
            const t = Object.getPrototypeOf(this);
            if (t.hasOwnProperty(Y) || t.finalize(), this.finalized = true, this._ensureClassProperties(), this._attributeToPropertyMap = new Map, this.hasOwnProperty(JSCompiler_renameProperty("properties", this))) { }
        }
        static _attributeNameForProperty(t, e) { const n = e.attribute; return false === n ? void 0 : "string" == typeof n ? n : "string" == typeof t ? t.toLowerCase() : void 0 } 
        static _valueHasChanged(t, e, n = X) { return n(t, e) } 

        initialize() {
            this._updateState = 0,
            this._updatePromise = new Promise((t => this._enableUpdatingResolver = t)),
            this._changedProperties = new Map,
            this.requestUpdateInternal() 
        }
        _applyInstanceProperties() { this._instanceProperties.forEach(((t, e) => this[e] = t)), this._instanceProperties = void 0 } 
        connectedCallback() { this.enableUpdating() } 
        enableUpdating() { void 0 !== this._enableUpdatingResolver && (this._enableUpdatingResolver(), this._enableUpdatingResolver = void 0) } 
        requestUpdateInternal(t, e, n) {
            let r = true;
            if (void 0 !== t) {
                const i = this.constructor;
                n = n || i.getPropertyOptions(t), i._valueHasChanged(this[t], e, n.hasChanged) ? (this._changedProperties.has(t) || this._changedProperties.set(t, e), true !== n.reflect || 16 & this._updateState) : r = false
            }!this._hasRequestedUpdate && r && (this._updatePromise = this._enqueueUpdate())
        }
        requestUpdate(t, e) { return this.requestUpdateInternal(t, e), this.updateComplete } 
        async _enqueueUpdate() { this._updateState = 4 | this._updateState; try { await this._updatePromise } catch (e) {} const t = this.performUpdate(); return null != t && await t, !this._hasRequestedUpdate } 
        get _hasRequestedUpdate() { return 4 & this._updateState } 
        get hasUpdated() { return 1 & this._updateState } 
        performUpdate() {
            if (!this._hasRequestedUpdate) return;
            this._instanceProperties && this._applyInstanceProperties();
            let t = false;
            const e = this._changedProperties;
            try {
                t = this.shouldUpdate(e),
                t ? this.update(e) : this._markUpdated()
            } catch (n) {
                throw t = false,
                this._markUpdated(),
                n
            } t && (1 & this._updateState || (this._updateState = 1 | this._updateState, this.firstUpdated(e)), this.updated(e))
        }
        _markUpdated() { this._changedProperties = new Map, this._updateState = -5 & this._updateState } 
        get updateComplete() { return this._getUpdateComplete() } 
        _getUpdateComplete() { return this.getUpdateComplete() } 
        getUpdateComplete() { return this._updatePromise } 
        shouldUpdate(t) { return true } update(t) { } updated(t) {}
    }
    Z.finalized = true;
    const J = t => e => "function" == typeof e ? ((t, e) => (window.customElements.define(t, e), e))(t, e) : ((t, e) => { const { kind: n, elements: r } = e; return { kind: n, elements: r, finisher(e) { window.customElements.define(t, e) } } })(t, e);

    // end custom element

}, function(t, e, n) { // 4
    "use strict";
    var o = n(7),
        s = n.n(o),
        l = n(11),
        u = n.n(l);

    function y(t) { return y = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(t) { return typeof t } : function(t) { return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t }, y(t) }

    function _(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    function x(t, e) { return x = Object.setPrototypeOf || function(t, e) { return t.__proto__ = e, t }, x(t, e) }

    function w(t) {
        var e = function() { if ("undefined" == typeof Reflect || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if ("function" == typeof Proxy) return true; try { return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (function() {}))), true } catch (t) { return false } }();
        return function() {
            var n, r = M(t);
            if (e) {
                var i = M(this).constructor;
                n = Reflect.construct(r, arguments, i)
            } else n = r.apply(this, arguments);
            return S(this, n)
        }
    }

    function S(t, e) { if (e && ("object" === y(e) || "function" == typeof e)) return e; if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined"); return function(t) { if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return t }(t) }

    function M(t) { return M = Object.setPrototypeOf ? Object.getPrototypeOf : function(t) { return t.__proto__ || Object.getPrototypeOf(t) }, M(t) }
    var H = function(t) {
            ! function(t, e) {
                if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
                t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: true, configurable: true } }), e && x(t, e)
            }(o, t);
            var e, n, r, i = w(o);

            function o(t) { var e; return function(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }(this, o), (e = i.call(this, t)), e }
            return e = o, n = [{ key: "canvas", get: function() { return this._canvas } 
            }, { key: "renderer", get: function() { return this._renderer.renderer } 
            }, { key: "height", get: function() { return this._height }
            }, {
                key: "_extra",
                value: function() {
                    var t = this;
                    this._width = 1,
                    this._height = 1;
                    this._frame = new u.a({ fps: FPS }), 
                    this._frame.on("", (function() { t.render() })), 
                    this.render()
                }
            }, {
                key: "play",
                value: function() { this._frame.play(); }
            }, {
                key: "render",
                value: function() {
                    this.lbt("frame"), 
                    this.lbt("prerender")
                }
            }], n && _(e.prototype, n), r && _(e, r), o
        }(s.a),
        A = new H({ });
    e.a = A;
    A.play() 
}, function(t, e, n) { // 5
    "use strict";
    Object.defineProperty(e, "__esModule", { value: true }), e.default = void 0;
    var r = n(6);
    e.default = function(t, e) { return r(t, e) };
}, function(t, e, n) { // 6
    /* lodash */
    (function(t, n) {
        function b(t, e, n) {
            return t.apply(e, n)
        }
        var W = Math.max,
            X = Date.now;

        function et(t, e, n) {
            (void 0 !== n || void 0 === n && !(e in t)) && it(t, e, n)
        }

        function it(t, e, n) { t[e] = n }
        var ot, st = function(t, e, n) {
            for (var r = -1, i = Object(t), o = n(t), s = o.length; s--;) {
                var a = o[ot ? s : ++r];
                if (false === e(i[a], a, i)) break
            } return t 
        };

        function ut(t) {
                var e = [];
                if (null != t)
                    for (var n in Object(t)) e.push(n);
                return e;
        }

        function ht(t, e, n, r, i) {
            t !== e && st(e, (function(o, s) {
                var c = r ? r(undefined, o, s + "", t, e, i) : void 0;
                void 0 === c && (c = o), et(t, s, c)
            }), Pt)
        }

        function ft(t, e) {
            return _t(function(t, e, n) {
                return e = W(void 0 === e ? t.length - 1 : e, 0),
                    function() {
                        for (var r = arguments, i = -1, o = W(r.length - e, 0), s = Array(o); ++i < o;) s[i] = r[e + i];
                        i = -1;
                        for (var a = Array(e + 1); ++i < e;) a[i] = r[i];
                        return a[e] = n(s), b(t, this, a)
                    }
            }(t, e, Ct), t)
        }
        var dt = Ct;

        var _t = function(t) {
            var e = 0,
                n = 0;
            return function() {
                var r = X(),
                    i = 16 - (r - n);
                if (n = r, i > 0) { if (++e >= 800) return arguments[0] } else e = 0;
                return t.apply(void 0, arguments)
            }
        }(dt);

        function Pt(t) { return ut(t) }
        var Rt, 
        Lt = (Rt = function(t, e, n, r) { ht(t, e, n, r) }, 
        ft((function(t, e) {
            var n = -1,
                r = e.length,
                i = r > 1 ? e[r - 1] : void 0,
                o = r > 2 ? e[2] : void 0;
            for (i = Rt.length > 3 && "function" == typeof i ? (r--, i) : void 0, o && function(t, e, n) { }(e[0], e[1], o) && (r = 1), t = Object(t); ++n < r;) {
                var s = e[n];
                s && Rt(t, s, n, i)
            }
            return t
        })));

        function Ct(t) { return t } n.exports = Lt
    }).call(this, function() {}, t)

}, function(t, e, n) { // 7
    "use strict";

    function r(t) { return r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(t) { return typeof t } : function(t) { return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t }, r(t) } Object.defineProperty(e, "__esModule", { value: true }), e.default = void 0;
    var i = l(n(9));

    function l(t) { return t && t.__esModule ? t : { default: t } }

    function u(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }

    function h(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    function f(t, e) { return f = Object.setPrototypeOf || function(t, e) { return t.__proto__ = e, t }, f(t, e) }

    function d(t) {
        var e = function() { if ("undefined" == typeof Reflect || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if ("function" == typeof Proxy) return true; try { return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (function() {}))), true } catch (t) { return false } }();
        return function() {
            var n, r = m(t);
            if (e) {
                var i = m(this).constructor;
                n = Reflect.construct(r, arguments, i)
            } else n = r.apply(this, arguments);
            return p(this, n)
        }
    }

    function p(t, e) { return !e || "object" !== r(e) && "function" != typeof e ? function(t) { if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return t }(t) : e }

    function m(t) { return m = Object.setPrototypeOf ? Object.getPrototypeOf : function(t) { return t.__proto__ || Object.getPrototypeOf(t) }, m(t) }
    var v = function(t) { return false },
        g = function(t) {
            ! function(t, e) {
                if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
                t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: true, configurable: true } }), e && f(t, e)
            }(l, t);
            var e, n, i = d(l);

            function l() {
                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                    e = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
                return u(this, l), i.call(this, t, e)
            }
            return e = l, n = [{
                key: "_extra",
                value: function() {
                    this._allEvents = [];
                }
            }, {
                key: "addEvent",
                value: function(t, e) {
                    var n = !(arguments.length > 2 && void 0 !== arguments[2]) || arguments[2],
                        r = [],
                        i = [];
                    if (v(t))
                        for (var o = 0; o < t.length; o++) {
                            var s = this._addEventHelper(t[o], e, n);
                            r.push([s, t[o]])
                        } else {
                            var a = this._addEventHelper(t, e, n);
                            r.push([a, t])
                        }
                    for (var c = 0; c < r.length; c++) r[c][0] && (this._allEvents.push({ id: r[c][0], name: r[c][1] }));
                    return v(t) ? i : i[0]
                }
            }, { key: "_addEventHelper", value: function(t, e, n) { return this._v[t].add(e, n) }
            }], n && h(e.prototype, n), l
        }(i.default);
    e.default = g
}, function(t, e, n) { // 8
    "use strict";

    function r(t) { return r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(t) { return typeof t } : function(t) { return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t }, r(t) }
    var i;

    function o(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }

    function s(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    function a(t, e, n) { return a = "undefined" != typeof Reflect && Reflect.get ? Reflect.get : function(t, e, n) { var r = function(t, e) { for (; !Object.prototype.hasOwnProperty.call(t, e) && null !== (t = h(t));); return t }(t, e); if (r) { var i = Object.getOwnPropertyDescriptor(r, e); return i.get ? i.get.call(n) : i.value } }, a(t, e, n || t) }

    function c(t, e) { return c = Object.setPrototypeOf || function(t, e) { return t.__proto__ = e, t }, c(t, e) }

    function l(t) {
        var e = function() { if ("undefined" == typeof Reflect || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if ("function" == typeof Proxy) return true; try { return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (function() {}))), true } catch (t) { return false } }();
        return function() {
            var n, r = h(t);
            if (e) {
                var i = h(this).constructor;
                n = Reflect.construct(r, arguments, i)
            } else n = r.apply(this, arguments);
            return u(this, n)
        }
    }

    function u(t, e) { return !e || "object" !== r(e) && "function" != typeof e ? function(t) { if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return t }(t) : e }

    function h(t) { return h = Object.setPrototypeOf ? Object.getPrototypeOf : function(t) { return t.__proto__ || Object.getPrototypeOf(t) }, h(t) } 
    Object.defineProperty(e, "__esModule", { value: true }), e.default = void 0;
    var f = function(t) {
        ! function(t, e) {
            if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
            t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: true, configurable: true } }), e && c(t, e)
        }(u, t);
        var e, n, r, i = l(u);

        function u() { return o(this, u), i.apply(this, arguments) }
        return e = u, (n = [
            {
                key: "_extra",
                value: function() {
                    this._loaded = false
                }
            }, {
                key: "loaded",
                get: function() { return this._loaded }
            }, {
                key: "_setEvents",
                value: function() {
                    var t = this;
                    this.add({
                        do: function() {
                            t._loaded = true
                        },
                        protected: true
                    }), window.addEventListener("load", this.launchAll.bind(this))
                }
            }]) && s(e.prototype, n), r && s(e, r), u
    }(((i = n(9)) && i.__esModule ? i : { default: i }).default);
    e.default = f
}, function(t, e, n) { // 9
    "use strict";
    Object.defineProperty(e, "__esModule", { value: true }), e.default = void 0;
    var r = a(n(16)),
        i = a(n(5)),
        s = a(function(t, e) { 0 === e ? t() : setTimeout(t.bind(this), e) });

    function a(t) { return t && t.__esModule ? t : { default: t } }

    function l(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }
    var u = function() {
        function t(e) {
            var o = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
            e = (0, r.default)(e),
            this._v = e.v,
            this._prop = (0, i.default)({ v: window.customApplication }, e),
            this._events = [],
            this.lbt = this.launchByTarget,
            o && this._init()
        }
        var e, n;
        return e = t, n = [{
            key: "_init",
            value: function() { this._extra(), this._setEvents() } 
        }, {
            key: "_setEvents",
            value: function() { } 
        }, {
            key: "add",
            value: function(t) {
                var e = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
                    r = { id: n, on: e, data: t };
                return this._events.push(r)
            }
        }, {
            key: "on",
            value: function(t, e) {
                var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
                    r = (0, i.default)(n, { target: t, do: e });
                return this.add(r)
            }
        }, {
            key: "_launch",
            value: function(t) {
                var e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
                if (t.on) {
                    var n = t.data,
                        r = n.do,
                        i = 0;
                    (s.default)(this._launchCallback.bind(this, r, e), i)
                }
            }
        }, {
            key: "_launchCallback",
            value: function(t, e) { e ? t(e) : t() } 
        }, {
            key: "launchAll",
            value: function() {
                var t = this;
                this._events.forEach((function(e) { t._launch(e) }))
            }
        }, {
            key: "launchByTarget",
            value: function(t) {
                var e = this,
                    n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
                this._events.forEach((function(r) { r.data.target === t && e._launch(r, n) }))
            }
        }], n && l(e.prototype, n), t
    }();
    e.default = u
}, function(t, e, n) { // 10
    "use strict";
    n.d(e, "a", (function() { return function i() { } }));
}, function(t, e, n) { // 11

    "use strict";

    function r(t) { return r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(t) { return typeof t } : function(t) { return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t }, r(t) } 
    Object.defineProperty(e, "__esModule", { value: true }), e.default = void 0;
    var i = s(n(7));

    function s(t) { return t && t.__esModule ? t : { default: t } }

    function a(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    function l(t, e) { return l = Object.setPrototypeOf || function(t, e) { return t.__proto__ = e, t }, l(t, e) }

    function u(t) {
        var e = function() { if ("undefined" == typeof Reflect || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if ("function" == typeof Proxy) return true; try { return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (function() {}))), true } catch (t) { } }();
        return function() {
            var n, r = f(t);
            if (e) {
                var i = f(this).constructor;
                n = Reflect.construct(r, arguments, i)
            } else n = r.apply(this, arguments);
            return h(this, n)
        }
    }

    function h(t, e) { return !e || "object" !== r(e) && "function" != typeof e ? function(t) { if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return t }(t) : e }

    function f(t) { return f = Object.setPrototypeOf ? Object.getPrototypeOf : function(t) { return t.__proto__ || Object.getPrototypeOf(t) }, f(t) }
    var d = function(t) {
        ! function(t, e) {
            if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
            t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: true, configurable: true } }), e && l(t, e)
        }(s, t);
        var e, n, r, i = u(s);

        function s(t) { return function(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }(this, s), i.call(this, t) }
        return e = s, n = [{
            key: "_extra",
            value: function() {
                this._time = null,
                this._frameSeg = -1,
                this._frame = null 
            }
        }, {
            key: "play",
            value: function() { return this._start(), true } 
        }, {
            key: "_start",
            value: function() {
                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 0;
                this._frame = window.requestAnimationFrame(this._start.bind(this)), null == this._time && (this._time = t);
                var e = Math.floor((t - this._time) / (1e3 / this._prop.fps));
                e > this._frameSeg && (this._frameSeg = e, this.launchAll())
            }
        }], n && a(e.prototype, n), r && a(e, r), s
    }(i.default);
    e.default = d

}, function(t, e, n) { // 12
    "use strict";

    function r(t) { return r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(t) { return typeof t } : function(t) { return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t }, r(t) } Object.defineProperty(e, "__esModule", { value: true }), e.default = void 0;
    var i = s(n(7));

    function s(t) { return t && t.__esModule ? t : { default: t } }

    function a(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    function c(t, e, n) { return c = "undefined" != typeof Reflect && Reflect.get ? Reflect.get : function(t, e, n) { var r = function(t, e) { for (; !Object.prototype.hasOwnProperty.call(t, e) && null !== (t = f(t));); return t }(t, e); if (r) { var i = Object.getOwnPropertyDescriptor(r, e); return i.get ? i.get.call(n) : i.value } }, c(t, e, n || t) }

    function l(t, e) { return l = Object.setPrototypeOf || function(t, e) { return t.__proto__ = e, t }, l(t, e) }

    function u(t) {
        var e = function() { if ("undefined" == typeof Reflect || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if ("function" == typeof Proxy) return true; try { return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (function() {}))), true } catch (t) { return false } }();
        return function() {
            var n, r = f(t);
            if (e) {
                var i = f(this).constructor;
                n = Reflect.construct(r, arguments, i)
            } else n = r.apply(this, arguments);
            return h(this, n)
        }
    }

    function h(t, e) { return !e || "object" !== r(e) && "function" != typeof e ? function(t) { if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return t }(t) : e }

    function f(t) { return f = Object.setPrototypeOf ? Object.getPrototypeOf : function(t) { return t.__proto__ || Object.getPrototypeOf(t) }, f(t) }
    var d = function(t) {
        ! function(t, e) {
            if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
            t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: true, configurable: true } }), e && l(t, e)
        }(s, t);
        var e, n, r, i = u(s);

        function s(t) { return function(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }(this, s), i.call(this, t) }
        return e = s, n = [{
            key: "defaultProp",
            get: function() {
                return (0, o.default)(c(f(s.prototype), "defaultProp", this))
            } 
        }, {
            key: "_extra",
            value: function() {
                this._v.customPages.push(this)
            } 
        }, {
            key: "create",
            value: function() { 
                return (
                    this._v.customPage = this
                ) 
            } 
        }, {
            key: "show",
            value: function() { return (this.lbt("show"), true) } 
        }, {
            key: "onPageShown",
            value: function(t) {
                var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
                this.on("show", (function() { t() }), e)
            }
        }], n && a(e.prototype, n), r && a(e, r), s
    }(i.default);
    e.default = d
}, function(t, e, n) { // 13

}, function(t, e, n) { // 14
    "use strict";

    function r(t) {
        return r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(t) { return typeof t } : function(t) { }, r(t)
    } Object.defineProperty(e, "__esModule", { value: true }),
    e.default = void 0;
    var i = l(n(7));

    function l(t) { return t && t.__esModule ? t : { default: t } }

    function u(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    function h(t, e, n) { return h = "undefined" != typeof Reflect && Reflect.get ? Reflect.get : function(t, e, n) { var r = function(t, e) { for (; !Object.prototype.hasOwnProperty.call(t, e) && null !== (t = m(t));); return t }(t, e); if (r) { var i = Object.getOwnPropertyDescriptor(r, e); return i.get ? i.get.call(n) : i.value } }, h(t, e, n || t) }

    function f(t, e) { return f = Object.setPrototypeOf || function(t, e) { return t.__proto__ = e, t }, f(t, e) }

    function d(t) {
        var e = function() { if ("undefined" == typeof Reflect || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if ("function" == typeof Proxy) return true; try { return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (function() {}))), true } catch (t) { return false } }();
        return function() {
            var n, r = m(t);
            if (e) {
                var i = m(this).constructor;
                n = Reflect.construct(r, arguments, i)
            } else n = r.apply(this, arguments);
            return p(this, n)
        }
    }

    function p(t, e) { return !e || "object" !== r(e) && "function" != typeof e ? function(t) { if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return t }(t) : e }

    function m(t) { return m = Object.setPrototypeOf ? Object.getPrototypeOf : function(t) { return t.__proto__ || Object.getPrototypeOf(t) }, m(t) }
    var y = function(t) {
            ! function(t, e) {
                if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
                t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: true, configurable: true } }), e && f(t, e)
            }(l, t);
            var e, n, r, i = d(l);

            function l(t) { return function(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }(this, l), i.call(this, t) }
            return e = l, n = [{
                key: "loaded",
                get: function() { return this._v.load.loaded } 
            }, {
                key: "_extra",
                value: function() {
                    h(m(l.prototype), "_extra", this).call(this);

                    this._time = +new Date,
                    this._progressFrameId = null
                }
            }, {
                key: "_setEvents",
                value: function() {
                    var t = this;
                    this.addEvent("load", { do: function() { t.lbt("show") } });
                }
            }], n && u(e.prototype, n), r && u(e, r), l
        }(i.default);
    e.default = y
}, function(t, e, n) { // 15
    /* page */
    "use strict";
    var r = n(14),
        i = n.n(r),
        c = n(0);

    (function() {
        return new i.a({ }).add({
            target: "show",
            do: function() {
                c.a.customPage.show();
            }
        })
    }());
}, function(t, e, n) { // 16
    /* page module */
    "use strict";
    Object.defineProperty(e, "__esModule", { value: true }),
    e.default = function(t) {
        var e = window.customApplication;
        void 0 === t.v && (t.v = e);
        return t
    }
}, function(t, e, n) { // 17 // animation
    "use strict";
    n.r(e);
    var r = n(0);

    var c = r.a.viewport;

    function l() {
        var t = c.size;
        r.a.html.style.setProperty("--vw", "".concat(t[0] / 100, "px")), r.a.html.style.setProperty("--vh", "".concat(t[1] / 100, "px"))
    }
    l(),
    r.a.load.add({ do: l.bind(void 0) });
    n(15);
    var h = n(1);

    var customHTML = n(3);

    var A = n(12),
        P = n.n(A);

    var it = function() { return { create: (function(){}).bind(this), enable: function() {} } }();

    var Dt;

    function It(t) { if ("object" != typeof t || null === t) return false; for (var e = t; null !== Object.getPrototypeOf(e);) e = Object.getPrototypeOf(e); return Object.getPrototypeOf(t) === e }

    function Bt(t, e, n) {
        var r;

        if ("function" == typeof e && void 0 === n && (n = e, e = void 0), void 0 !== n) { if ("function" != typeof n) throw new Error(); return n(Bt)(t, e) }
        if ("function" != typeof t) throw new Error();
        var i = t,
            o = e,
            s = [],
            a = s,
            c = false;

        function u() { return o }

        function f(t) {
            if (!It(t)) throw new Error();
            if (void 0 === t.type) throw new Error();
            try { o = i(o, t) } finally { c = false }
            for (var e = s = a, n = 0; n < e.length; n++) {
                (0, e[n])()
            }
            return t
        }

        function p() { }
        return f({ type: " " }), (r = { dispatch: f, getState: u })["function"] = p, r
    }(function(t) { t.enable = "enable", t.disable = "disable" })(Dt || (Dt = {}));
    var Ft = Bt((function() {
        var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : { value: { enabled: false } },
            e = arguments.length > 1 ? arguments[1] : void 0;
        switch (e.type) {
            case Dt.enable:
                return t.value.enabled = true, t;
            case Dt.disable:
                return t.value.enabled = false, t;
            default:
                return t
        }
    }));

    var Gt = n(10);

    function Xt(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }

    function qt(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    function Yt(t, e, n) { return Yt = "undefined" != typeof Reflect && Reflect.get ? Reflect.get : function(t, e, n) { var r = function(t, e) { return t }(t, e); if (r) { var i = Object.getOwnPropertyDescriptor(r, e); return i.get ? i.get.call(n) : i.value } }, Yt(t, e, n || t) }

    function Zt(t, e) { return Zt = Object.setPrototypeOf || function(t, e) { return t.__proto__ = e, t }, Zt(t, e) }

    function Jt(t) {
        var e = function() { if ("undefined" == typeof Reflect || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if ("function" == typeof Proxy) return true; try { return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (function() {}))), true } catch (t) { return false } }();
        return function() {
            var n, r = Qt(t);
            if (e) {
                var i = Qt(this).constructor;
                n = Reflect.construct(r, arguments, i)
            } else n = r.apply(this, arguments);
        }
    }

    function Qt(t) { return Qt = Object.setPrototypeOf ? Object.getPrototypeOf : function(t) { return t.__proto__ || Object.getPrototypeOf(t) }, Qt(t) } 
    Promise.all([n.e(8), n.e(9)]).then(n.bind(null, 220));
    var $t = function(t) {
            ! function(t, e) {
                if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
                t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: true, configurable: true } }), e && Zt(t, e)
            }(c, t);
            var e, o, s, a = Jt(c);

            function c() {
                return Xt(this, c), (a.apply(this, arguments))
            }
            return e = c, o = [
                {
                    key: "show",
                    value: function() { 
                        var t = this;
                        return (this._handleCanBeShown().then((function() { Yt(Qt(c.prototype), "show", t).call(t),
                        it.enable();
                        Ft.dispatch({ type: Dt.enable }) }))) 
                    }
                }, {
                    key: "_handleCanBeShown",
                    value: function() {
                        return new Promise((function(e) { Object(Gt.a)(true), (Object(Gt.a)(false), e()) })) }
                }], o && qt(e.prototype, o), s && qt(e, s), c
        }(P.a),
        pe = n(4);
        new $t();

    ! function() {
        for (var t = undefined, e = false, n = false, o = 0; o < r.a.customPages.length; o++) {
            var s = r.a.customPages[o];
            s.name === t && (e = s), s.name === (n = s)
        }
        if (e) e.create();
        else {
            if (!n) throw new Error("Default page doesn't exist!");
            n.create()
        }
    }();

    function gr(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    /* animation settings */
    function yr(t) {
        t.width = t.clientWidth / 2,
        t.height = t.clientHeight / 2;
        var e = CONFIG;

        function n() {
            this.id = -1,
            this.x = 0,
            this.y = 0,
            this.dx = 0,
            this.dy = 0,
            this.down = false,
            this.moved = false,
            this.color = [30, 30, 300]
        }
        var i = [],
            o = [],
            s = [];
        i.push(new n);
        var a = function(t) {
                var e, n, r = { alpha: true, depth: false, stencil: false, antialias: false, preserveDrawingBuffer: false },
                    i = t.getContext("webgl2", r),
                    o = i;
                o || (i = t.getContext("webgl", r) || t.getContext("experimental-webgl", r));
                o ? (i.getExtension("EXT_color_buffer_float"), n = i.getExtension("OES_texture_float_linear")) : (e = i.getExtension("OES_texture_half_float"), n = i.getExtension("OES_texture_half_float_linear"));
                i.clearColor(0, 0, 0, 1);
                var s, a, c, l = o ? i.HALF_FLOAT : e.HALF_FLOAT_OES;
                o ? (s = u(i, i.RGBA16F, i.RGBA, l), a = u(i, i.RG16F, i.RG, l), c = u(i, i.R16F, i.RED, l)) : (s = u(i, i.RGBA, i.RGBA, l), a = u(i, i.RGBA, i.RGBA, l), c = u(i, i.RGBA, i.RGBA, l));
                return { gl: i, ext: { formatRGBA: s, formatRG: a, formatR: c, halfFloatTexType: l, supportLinearFiltering: n } }
            }(t),
            c = a.gl,
            l = a.ext;

        function u(t, e, n, r) {
            if (! function(t, e, n, r) {
                    var i = t.createTexture();
                    t.bindTexture(t.TEXTURE_2D, i), t.texParameteri(t.TEXTURE_2D, t.TEXTURE_MIN_FILTER, t.NEAREST), t.texParameteri(t.TEXTURE_2D, t.TEXTURE_MAG_FILTER, t.NEAREST), t.texParameteri(t.TEXTURE_2D, t.TEXTURE_WRAP_S, t.CLAMP_TO_EDGE), t.texParameteri(t.TEXTURE_2D, t.TEXTURE_WRAP_T, t.CLAMP_TO_EDGE), t.texImage2D(t.TEXTURE_2D, 0, e, 4, 4, 0, n, r, null);
                    var o = t.createFramebuffer();
                    if (t.bindFramebuffer(t.FRAMEBUFFER, o), t.framebufferTexture2D(t.FRAMEBUFFER, t.COLOR_ATTACHMENT0, t.TEXTURE_2D, i, 0), t.checkFramebufferStatus(t.FRAMEBUFFER) !== t.FRAMEBUFFER_COMPLETE) return false;
                    return true
                }(t, e, n, r)) switch (e) {
                case t.R16F:
                    return u(t, t.RG16F, t.RG, r);
                case t.RG16F:
                    return u(t, t.RGBA16F, t.RGBA, r);
                default:
                    return null
            }
            return { internalFormat: e, format: n }
        }
        l.supportLinearFiltering || (e.SHADING = false, e.BLOOM = false);
        var h = function() {
            function t(e, n) {
                if (function(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }(this, t), this.uniforms = {}, this.program = c.createProgram(), c.attachShader(this.program, e), c.attachShader(this.program, n), c.linkProgram(this.program), !c.getProgramParameter(this.program, c.LINK_STATUS)) throw c.getProgramInfoLog(this.program);
                for (var r = c.getProgramParameter(this.program, c.ACTIVE_UNIFORMS), i = 0; i < r; i++) {
                    var o = c.getActiveUniform(this.program, i).name;
                    this.uniforms[o] = c.getUniformLocation(this.program, o)
                }
            }
            var e, n, r;
            return e = t, (n = [{
                key: "bind",
                value: function() { c.useProgram(this.program) } 
            }]) && gr(e.prototype, n), r && gr(e, r), t
        }();

        function f(t, e) { var n = c.createShader(t); if (c.shaderSource(n, e), c.compileShader(n), !c.getShaderParameter(n, c.COMPILE_STATUS)) throw c.getShaderInfoLog(n); return n }
        
        var d, p, m, v, g, y, _, b, x, w, S = f(c.VERTEX_SHADER, "\n    precision highp float;\n    attribute vec2 aPosition;\n    varying vec2 vUv;\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform vec2 texelSize;\n    void main () {\n        vUv = aPosition * 0.5 + 0.5;\n        vL = vUv - vec2(texelSize.x, 0.0);\n        vR = vUv + vec2(texelSize.x, 0.0);\n        vT = vUv + vec2(0.0, texelSize.y);\n        vB = vUv - vec2(0.0, texelSize.y);\n        gl_Position = vec4(aPosition, 0.0, 1.0);\n    }\n"),
            M = f(c.FRAGMENT_SHADER, "\n    precision mediump float;\n    precision mediump sampler2D;\n    varying highp vec2 vUv;\n    uniform sampler2D uTexture;\n    uniform float value;\n    void main () {\n        gl_FragColor = value * texture2D(uTexture, vUv);\n    }\n"),
            E = f(c.FRAGMENT_SHADER, "\n    precision mediump float;\n    uniform vec4 color;\n    void main () {\n        gl_FragColor = color;\n    }\n"),
            T = f(c.FRAGMENT_SHADER, "\n    precision highp float;\n    precision highp sampler2D;\n    varying vec2 vUv;\n    uniform sampler2D uTexture;\n    uniform float aspectRatio;\n    #define SCALE 25.0\n    void main () {\n        vec2 uv = floor(vUv * SCALE * vec2(aspectRatio, 1.0));\n        float v = mod(uv.x + uv.y, 2.0);\n        v = v * 0.1 + 0.8;\n        gl_FragColor = vec4(vec3(v), 1.0);\n    }\n"),
            O = f(c.FRAGMENT_SHADER, "\n    precision highp float;\n    precision highp sampler2D;\n    varying vec2 vUv;\n    uniform sampler2D uTexture;\n    void main () {\n        vec3 C = texture2D(uTexture, vUv).rgb;\n        float a = max(C.r, max(C.g, C.b));\n        gl_FragColor = vec4(C, a);\n    }\n"),
            H = f(c.FRAGMENT_SHADER, "\n    precision highp float;\n    precision highp sampler2D;\n    varying vec2 vUv;\n    uniform sampler2D uTexture;\n    uniform sampler2D uBloom;\n    uniform sampler2D uDithering;\n    uniform vec2 ditherScale;\n    void main () {\n        vec3 C = texture2D(uTexture, vUv).rgb;\n        vec3 bloom = texture2D(uBloom, vUv).rgb;\n        vec3 noise = texture2D(uDithering, vUv * ditherScale).rgb;\n        noise = noise * 2.0 - 1.0;\n        bloom += noise / 800.0;\n        bloom = pow(bloom.rgb, vec3(1.0 / 2.2));\n        C += bloom;\n        float a = max(C.r, max(C.g, C.b));\n        gl_FragColor = vec4(C, a);\n    }\n"),
            A = f(c.FRAGMENT_SHADER, "\n    precision highp float;\n    precision highp sampler2D;\n    varying vec2 vUv;\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform sampler2D uTexture;\n    uniform vec2 texelSize;\n    void main () {\n        vec3 L = texture2D(uTexture, vL).rgb;\n        vec3 R = texture2D(uTexture, vR).rgb;\n        vec3 T = texture2D(uTexture, vT).rgb;\n        vec3 B = texture2D(uTexture, vB).rgb;\n        vec3 C = texture2D(uTexture, vUv).rgb;\n        float dx = length(R) - length(L);\n        float dy = length(T) - length(B);\n        vec3 n = normalize(vec3(dx, dy, length(texelSize)));\n        vec3 l = vec3(0.0, 0.0, 1.0);\n        float diffuse = clamp(dot(n, l) + 0.7, 0.7, 1.0);\n        C.rgb *= diffuse;\n        float a = max(C.r, max(C.g, C.b));\n        gl_FragColor = vec4(C, a);\n    }\n"),
            P = f(c.FRAGMENT_SHADER, "\n    precision highp float;\n    precision highp sampler2D;\n    varying vec2 vUv;\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform sampler2D uTexture;\n    uniform sampler2D uBloom;\n    uniform sampler2D uDithering;\n    uniform vec2 ditherScale;\n    uniform vec2 texelSize;\n    void main () {\n        vec3 L = texture2D(uTexture, vL).rgb;\n        vec3 R = texture2D(uTexture, vR).rgb;\n        vec3 T = texture2D(uTexture, vT).rgb;\n        vec3 B = texture2D(uTexture, vB).rgb;\n        vec3 C = texture2D(uTexture, vUv).rgb;\n        float dx = length(R) - length(L);\n        float dy = length(T) - length(B);\n        vec3 n = normalize(vec3(dx, dy, length(texelSize)));\n        vec3 l = vec3(0.0, 0.0, 1.0);\n        float diffuse = clamp(dot(n, l) + 0.7, 0.7, 1.0);\n        C *= diffuse;\n        vec3 bloom = texture2D(uBloom, vUv).rgb;\n        vec3 noise = texture2D(uDithering, vUv * ditherScale).rgb;\n        noise = noise * 2.0 - 1.0;\n        bloom += noise / 800.0;\n        bloom = pow(bloom.rgb, vec3(1.0 / 2.2));\n        C += bloom;\n        float a = max(C.r, max(C.g, C.b));\n        gl_FragColor = vec4(C, a);\n    }\n"),
            R = f(c.FRAGMENT_SHADER, "\n    precision mediump float;\n    precision mediump sampler2D;\n    varying vec2 vUv;\n    uniform sampler2D uTexture;\n    uniform vec3 curve;\n    uniform float threshold;\n    void main () {\n        vec3 c = texture2D(uTexture, vUv).rgb;\n        float br = max(c.r, max(c.g, c.b));\n        float rq = clamp(br - curve.x, 0.0, curve.y);\n        rq = curve.z * rq * rq;\n        c *= max(rq, br - threshold) / max(br, 0.0001);\n        gl_FragColor = vec4(c, 0.0);\n    }\n"),
            L = f(c.FRAGMENT_SHADER, "\n    precision mediump float;\n    precision mediump sampler2D;\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform sampler2D uTexture;\n    void main () {\n        vec4 sum = vec4(0.0);\n        sum += texture2D(uTexture, vL);\n        sum += texture2D(uTexture, vR);\n        sum += texture2D(uTexture, vT);\n        sum += texture2D(uTexture, vB);\n        sum *= 0.25;\n        gl_FragColor = sum;\n    }\n"),
            C = f(c.FRAGMENT_SHADER, "\n    precision mediump float;\n    precision mediump sampler2D;\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform sampler2D uTexture;\n    uniform float intensity;\n    void main () {\n        vec4 sum = vec4(0.0);\n        sum += texture2D(uTexture, vL);\n        sum += texture2D(uTexture, vR);\n        sum += texture2D(uTexture, vT);\n        sum += texture2D(uTexture, vB);\n        sum *= 0.25;\n        gl_FragColor = sum * intensity;\n    }\n"),
            k = f(c.FRAGMENT_SHADER, "\n    precision highp float;\n    precision highp sampler2D;\n    varying vec2 vUv;\n    uniform sampler2D uTarget;\n    uniform float aspectRatio;\n    uniform vec3 color;\n    uniform vec2 point;\n    uniform float radius;\n    void main () {\n        vec2 p = vUv - point.xy;\n        p.x *= aspectRatio;\n        vec3 splat = exp(-dot(p, p) / radius) * color;\n        vec3 base = texture2D(uTarget, vUv).xyz;\n        gl_FragColor = vec4(base + splat, 1.0);\n    }\n"),
            D = f(c.FRAGMENT_SHADER, "\n    precision highp float;\n    precision highp sampler2D;\n    varying vec2 vUv;\n    uniform sampler2D uVelocity;\n    uniform sampler2D uSource;\n    uniform vec2 texelSize;\n    uniform vec2 dyeTexelSize;\n    uniform float dt;\n    uniform float dissipation;\n    vec4 bilerp (sampler2D sam, vec2 uv, vec2 tsize) {\n        vec2 st = uv / tsize - 0.5;\n        vec2 iuv = floor(st);\n        vec2 fuv = fract(st);\n        vec4 a = texture2D(sam, (iuv + vec2(0.5, 0.5)) * tsize);\n        vec4 b = texture2D(sam, (iuv + vec2(1.5, 0.5)) * tsize);\n        vec4 c = texture2D(sam, (iuv + vec2(0.5, 1.5)) * tsize);\n        vec4 d = texture2D(sam, (iuv + vec2(1.5, 1.5)) * tsize);\n        return mix(mix(a, b, fuv.x), mix(c, d, fuv.x), fuv.y);\n    }\n    void main () {\n        vec2 coord = vUv - dt * bilerp(uVelocity, vUv, texelSize).xy * texelSize;\n        gl_FragColor = dissipation * bilerp(uSource, coord, dyeTexelSize);\n        gl_FragColor.a = 1.0;\n    }\n"),
            N = f(c.FRAGMENT_SHADER, "\n    precision highp float;\n    precision highp sampler2D;\n    varying vec2 vUv;\n    uniform sampler2D uVelocity;\n    uniform sampler2D uSource;\n    uniform vec2 texelSize;\n    uniform float dt;\n    uniform float dissipation;\n    void main () {\n        vec2 coord = vUv - dt * texture2D(uVelocity, vUv).xy * texelSize;\n        gl_FragColor = dissipation * texture2D(uSource, coord);\n        gl_FragColor.a = 1.0;\n    }\n"),
            z = f(c.FRAGMENT_SHADER, "\n    precision mediump float;\n    precision mediump sampler2D;\n    varying highp vec2 vUv;\n    varying highp vec2 vL;\n    varying highp vec2 vR;\n    varying highp vec2 vT;\n    varying highp vec2 vB;\n    uniform sampler2D uVelocity;\n    void main () {\n        float L = texture2D(uVelocity, vL).x;\n        float R = texture2D(uVelocity, vR).x;\n        float T = texture2D(uVelocity, vT).y;\n        float B = texture2D(uVelocity, vB).y;\n        vec2 C = texture2D(uVelocity, vUv).xy;\n        if (vL.x < 0.0) { L = -C.x; }\n        if (vR.x > 1.0) { R = -C.x; }\n        if (vT.y > 1.0) { T = -C.y; }\n        if (vB.y < 0.0) { B = -C.y; }\n        float div = 0.5 * (R - L + T - B);\n        gl_FragColor = vec4(div, 0.0, 0.0, 1.0);\n    }\n"),
            j = f(c.FRAGMENT_SHADER, "\n    precision mediump float;\n    precision mediump sampler2D;\n    varying highp vec2 vUv;\n    varying highp vec2 vL;\n    varying highp vec2 vR;\n    varying highp vec2 vT;\n    varying highp vec2 vB;\n    uniform sampler2D uVelocity;\n    void main () {\n        float L = texture2D(uVelocity, vL).y;\n        float R = texture2D(uVelocity, vR).y;\n        float T = texture2D(uVelocity, vT).x;\n        float B = texture2D(uVelocity, vB).x;\n        float vorticity = R - L - T + B;\n        gl_FragColor = vec4(0.5 * vorticity, 0.0, 0.0, 1.0);\n    }\n"),
            I = f(c.FRAGMENT_SHADER, "\n    precision highp float;\n    precision highp sampler2D;\n    varying vec2 vUv;\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform sampler2D uVelocity;\n    uniform sampler2D uCurl;\n    uniform float curl;\n    uniform float dt;\n    void main () {\n        float L = texture2D(uCurl, vL).x;\n        float R = texture2D(uCurl, vR).x;\n        float T = texture2D(uCurl, vT).x;\n        float B = texture2D(uCurl, vB).x;\n        float C = texture2D(uCurl, vUv).x;\n        vec2 force = 0.5 * vec2(abs(T) - abs(B), abs(R) - abs(L));\n        force /= length(force) + 0.0001;\n        force *= curl * C;\n        force.y *= -1.0;\n        vec2 vel = texture2D(uVelocity, vUv).xy;\n        gl_FragColor = vec4(vel + force * dt, 0.0, 1.0);\n    }\n"),
            B = f(c.FRAGMENT_SHADER, "\n    precision mediump float;\n    precision mediump sampler2D;\n    varying highp vec2 vUv;\n    varying highp vec2 vL;\n    varying highp vec2 vR;\n    varying highp vec2 vT;\n    varying highp vec2 vB;\n    uniform sampler2D uPressure;\n    uniform sampler2D uDivergence;\n    vec2 boundary (vec2 uv) {\n        return uv;\n        // uncomment if you use wrap or repeat texture mode\n        // uv = min(max(uv, 0.0), 1.0);\n        // return uv;\n    }\n    void main () {\n        float L = texture2D(uPressure, boundary(vL)).x;\n        float R = texture2D(uPressure, boundary(vR)).x;\n        float T = texture2D(uPressure, boundary(vT)).x;\n        float B = texture2D(uPressure, boundary(vB)).x;\n        float C = texture2D(uPressure, vUv).x;\n        float divergence = texture2D(uDivergence, vUv).x;\n        float pressure = (L + R + B + T - divergence) * 0.25;\n        gl_FragColor = vec4(pressure, 0.0, 0.0, 1.0);\n    }\n"),
            F = f(c.FRAGMENT_SHADER, "\n    precision mediump float;\n    precision mediump sampler2D;\n    varying highp vec2 vUv;\n    varying highp vec2 vL;\n    varying highp vec2 vR;\n    varying highp vec2 vT;\n    varying highp vec2 vB;\n    uniform sampler2D uPressure;\n    uniform sampler2D uVelocity;\n    vec2 boundary (vec2 uv) {\n        return uv;\n        // uv = min(max(uv, 0.0), 1.0);\n        // return uv;\n    }\n    void main () {\n        float L = texture2D(uPressure, boundary(vL)).x;\n        float R = texture2D(uPressure, boundary(vR)).x;\n        float T = texture2D(uPressure, boundary(vT)).x;\n        float B = texture2D(uPressure, boundary(vB)).x;\n        vec2 velocity = texture2D(uVelocity, vUv).xy;\n        velocity.xy -= vec2(R - L, T - B);\n        gl_FragColor = vec4(velocity, 0.0, 1.0);\n    }\n"),
            U = (c.bindBuffer(c.ARRAY_BUFFER, c.createBuffer()), c.bufferData(c.ARRAY_BUFFER, new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]), c.STATIC_DRAW), c.bindBuffer(c.ELEMENT_ARRAY_BUFFER, c.createBuffer()), c.bufferData(c.ELEMENT_ARRAY_BUFFER, new Uint16Array([0, 1, 2, 0, 2, 3]), c.STATIC_DRAW), c.vertexAttribPointer(0, 2, c.FLOAT, false, 0, 0), c.enableVertexAttribArray(0), function(t) { c.bindFramebuffer(c.FRAMEBUFFER, t), c.drawElements(c.TRIANGLES, 6, c.UNSIGNED_SHORT, 0) }),
            G = function(t) {
                var e = c.createTexture();
                c.bindTexture(c.TEXTURE_2D, e), c.texParameteri(c.TEXTURE_2D, c.TEXTURE_MIN_FILTER, c.LINEAR), c.texParameteri(c.TEXTURE_2D, c.TEXTURE_MAG_FILTER, c.LINEAR), c.texParameteri(c.TEXTURE_2D, c.TEXTURE_WRAP_S, c.REPEAT), c.texParameteri(c.TEXTURE_2D, c.TEXTURE_WRAP_T, c.REPEAT), c.texImage2D(c.TEXTURE_2D, 0, c.RGB, 1, 1, 0, c.RGB, c.UNSIGNED_BYTE, new Uint8Array([255, 255, 255]));
                var n = { texture: e, width: 1, height: 1, attach: function(t) { return c.activeTexture(c.TEXTURE0 + t), c.bindTexture(c.TEXTURE_2D, e), t } },
                    r = new Image;
                return r.crossOrigin = "anonymous", r.src = t, n
            }("data:image/png;base64,"),
            V = new h(S, M),
            W = new h(S, E),
            X = new h(S, T),
            q = new h(S, O),
            Y = new h(S, H),
            Z = new h(S, A),
            J = new h(S, P),
            K = new h(S, R),
            Q = new h(S, L),
            $ = new h(S, C),
            tt = new h(S, k),
            et = new h(S, l.supportLinearFiltering ? N : D),
            nt = new h(S, z),
            rt = new h(S, j),
            it = new h(S, I),
            ot = new h(S, B),
            st = new h(S, F);

        function at() {
            var t = mt(e.SIM_RESOLUTION),
                n = mt(e.DYE_RESOLUTION);
            d = t.width, p = t.height, m = n.width, v = n.height;
            var r = l.halfFloatTexType,
                i = l.formatRGBA,
                o = l.formatRG,
                a = l.formatR,
                u = l.supportLinearFiltering ? c.LINEAR : c.NEAREST;
            g = null == g ? lt(m, v, i.internalFormat, i.format, r, u) : ut(g, m, v, i.internalFormat, i.format, r, u), y = null == y ? lt(d, p, o.internalFormat, o.format, r, u) : ut(y, d, p, o.internalFormat, o.format, r, u), _ = ct(d, p, a.internalFormat, a.format, r, c.NEAREST), b = ct(d, p, a.internalFormat, a.format, r, c.NEAREST), x = lt(d, p, a.internalFormat, a.format, r, c.NEAREST),
            function() {
                var t = mt(e.BLOOM_RESOLUTION),
                    n = l.halfFloatTexType,
                    r = l.formatRGBA,
                    i = l.supportLinearFiltering ? c.LINEAR : c.NEAREST;
                w = ct(t.width, t.height, r.internalFormat, r.format, n, i), s.length = 0;
                for (var o = 0; o < e.BLOOM_ITERATIONS; o++) {
                    var a = t.width >> o + 1,
                        u = t.height >> o + 1;
                    if (a < 2 || u < 2) break;
                    var h = ct(a, u, r.internalFormat, r.format, n, i);
                    s.push(h)
                }
            }()
        }

        function ct(t, e, n, r, i, o) {
            c.activeTexture(c.TEXTURE0);
            var s = c.createTexture();
            c.bindTexture(c.TEXTURE_2D, s), c.texParameteri(c.TEXTURE_2D, c.TEXTURE_MIN_FILTER, o), c.texParameteri(c.TEXTURE_2D, c.TEXTURE_MAG_FILTER, o), c.texParameteri(c.TEXTURE_2D, c.TEXTURE_WRAP_S, c.CLAMP_TO_EDGE), c.texParameteri(c.TEXTURE_2D, c.TEXTURE_WRAP_T, c.CLAMP_TO_EDGE), c.texImage2D(c.TEXTURE_2D, 0, n, t, e, 0, r, i, null);
            var a = c.createFramebuffer();
            return c.bindFramebuffer(c.FRAMEBUFFER, a), c.framebufferTexture2D(c.FRAMEBUFFER, c.COLOR_ATTACHMENT0, c.TEXTURE_2D, s, 0), c.viewport(0, 0, t, e), c.clear(c.COLOR_BUFFER_BIT), { texture: s, fbo: a, width: t, height: e, attach: function(t) { return c.activeTexture(c.TEXTURE0 + t), c.bindTexture(c.TEXTURE_2D, s), t } }
        }

        function lt(t, e, n, r, i, o) {
            var s = ct(t, e, n, r, i, o),
                a = ct(t, e, n, r, i, o);
            return {
                get read() { return s },
                set read(t) { s = t },
                get write() { return a },
                set write(t) { a = t },
                swap: function() {
                    var t = s;
                    s = a, a = t
                }
            }
        }

        function ut(t, e, n, r, i, o, s) {
            return t.read = function(t, e, n, r, i, o, s) { var a = ct(e, n, r, i, o, s); return V.bind(), c.uniform1i(V.uniforms.uTexture, t.attach(0)), c.uniform1f(V.uniforms.value, 1), U(a.fbo), a }(t.read, e, n, r, i, o, s), t.write = ct(e, n, r, i, o, s), t } at();
            var ht = Date.now(),
            ft = [1, 1];

        function dt(n, r, i, o, s) { c.viewport(0, 0, d, p), tt.bind(), c.uniform1i(tt.uniforms.uTarget, y.read.attach(0)), c.uniform1f(tt.uniforms.aspectRatio, t.width / t.height), c.uniform2f(tt.uniforms.point, n / t.width, 1 - r / t.height), c.uniform3f(tt.uniforms.color, i, -o, 1), c.uniform1f(tt.uniforms.radius, e.SPLAT_RADIUS / 100), U(y.write.fbo), y.swap(), c.viewport(0, 0, m, v), c.uniform1i(tt.uniforms.uTarget, g.read.attach(0)), c.uniform3f(tt.uniforms.color, s.r, s.g, s.b), U(g.write.fbo), g.swap() }

        function pt() {
            var t = function(t, e, n) {
                var r, i, o, s, a, c, l, u;
                switch (s = Math.floor(6 * t), c = n * (1 - e), l = n * (1 - (a = 6 * t - s) * e), u = n * (1 - (1 - a) * e), s % 6) {
                    case 0:
                        r = n, i = u, o = c;
                        break;
                    case 1:
                        r = l, i = n, o = c;
                        break;
                    case 2:
                        r = c, i = n, o = u;
                        break;
                    case 3:
                        r = c, i = l, o = n;
                        break;
                    case 4:
                        r = u, i = c, o = n;
                        break;
                    case 5:
                        r = n, i = c, o = l
                }
                return { r: r, g: i, b: o }
            }(Math.random(), 1, 1);
            return t.r *= .15, t.g *= .15, t.b *= .15, t
        }

        function mt(t) {
            var e = c.drawingBufferWidth / c.drawingBufferHeight;
            e < 1 && (e = 1 / e);
            var n = Math.round(t * e),
                r = Math.round(t);
            return c.drawingBufferWidth > c.drawingBufferHeight ? { width: n, height: r } : { width: r, height: n }
        }

        function vt(t, e, n) { return { x: e / t.width, y: n / t.height } }
        return i[0].down = true, i[0].color = pt(), window.addEventListener("mousemove", (function(t) { i[0].moved = i[0].down, i[0].dx = 5 * (t.clientX - i[0].x), i[0].dy = 5 * (t.clientY - i[0].y), i[0].x = t.clientX, i[0].y = t.clientY })), window.addEventListener("touchmove", (function(t) {
            for (var e = t.targetTouches, n = 0; n < e.length; n++) {
                var r = i[n];
                r.moved = r.down, r.dx = 8 * (e[n].clientX - r.x), r.dy = 8 * (e[n].clientY - r.y), r.x = e[n].clientX, r.y = e[n].clientY
            }
        }), false), window.addEventListener("touchstart", (function(t) { for (var e = t.targetTouches, r = 0; r < e.length; r++) r >= i.length && i.push(new n), i[r].id = e[r].identifier, i[r].down = true, i[r].x = e[r].clientX, i[r].y = e[r].clientY, i[r].color = pt() })), window.addEventListener("touchend", (function(t) {
            for (var e = t.changedTouches, n = 0; n < e.length; n++)
                for (var r = 0; r < i.length; r++) e[n].identifier === i[r].id && (i[r].down = false)
        })), {
            update: function() {
                ! function() {
                    if (document.getElementById("app").classList.contains("dark-mode")) {
                        if(col.r >= CONFIG.BACK_COLOR_ALT.r) {
                            col.r -= 12;
                            col.g -= 12;
                            col.b -= 12;
                        }
                    } else {
                        if(col.r <= CONFIG.BACK_COLOR.r) {
                            col.r += 12;
                            col.g += 12;
                            col.b += 12;
                        }
                    }
                    var e = r.a.viewport.size;
                    ft[0] === e[0] && ft[1] === e[1] || (t.width = e[0], t.height = e[1], at());
                    ft = e.slice(0)
                }(),
                function() {
                    o.length > 0 && function(e) {
                        for (var n = 0; n < e; n++) {
                            var r = pt();
                            r.r *= 10, r.g *= 10, r.b *= 10, dt(t.width * Math.random(), t.height * Math.random(), 1e3 * (Math.random() - .5), 1e3 * (Math.random() - .5), r)
                        }
                    }(o.pop());
                    for (var n = 0; n < i.length; n++) {
                        var r = i[n];
                        r.moved && (dt(r.x, r.y, r.dx, r.dy, r.color), r.moved = false)
                    }
                    if (!e.COLORFUL) return;
                    if (ht + 100 < Date.now()) { ht = Date.now(); for (var s = 0; s < i.length; s++) { i[s].color = pt() } }
                }(),
                function(t) {
                    c.disable(c.BLEND),
                    c.viewport(0, 0, d, p),
                    rt.bind(),
                    c.uniform2f(rt.uniforms.texelSize, 0.1 / d, 0.1 / p),
                    c.uniform1i(rt.uniforms.uVelocity, y.read.attach(0)),
                    U(b.fbo),
                    it.bind(),
                    c.uniform2f(it.uniforms.texelSize, 0.1 / d, 0.1 / p),
                    c.uniform1i(it.uniforms.uVelocity, y.read.attach(0)),
                    c.uniform1i(it.uniforms.uCurl, b.attach(1)),
                    c.uniform1f(it.uniforms.curl, e.CURL), c.uniform1f(it.uniforms.dt, t), U(y.write.fbo), y.swap(), nt.bind(), c.uniform2f(nt.uniforms.texelSize, 1 / d, 1 / p), c.uniform1i(nt.uniforms.uVelocity, y.read.attach(0)), U(_.fbo), V.bind(), c.uniform1i(V.uniforms.uTexture, x.read.attach(0)), c.uniform1f(V.uniforms.value, e.PRESSURE_DISSIPATION), U(x.write.fbo), x.swap(), ot.bind(), c.uniform2f(ot.uniforms.texelSize, 1 / d, 1 / p), c.uniform1i(ot.uniforms.uDivergence, _.attach(0));
                    for (var n = 0; n < e.PRESSURE_ITERATIONS; n++) c.uniform1i(ot.uniforms.uPressure, x.read.attach(1)), U(x.write.fbo), x.swap();
                    st.bind(), c.uniform2f(st.uniforms.texelSize, 1 / d, 1 / p), c.uniform1i(st.uniforms.uPressure, x.read.attach(0)), c.uniform1i(st.uniforms.uVelocity, y.read.attach(1)), U(y.write.fbo), y.swap(), et.bind(), c.uniform2f(et.uniforms.texelSize, 1 / d, 1 / p), l.supportLinearFiltering || c.uniform2f(et.uniforms.dyeTexelSize, 1 / d, 1 / p);
                    var r = y.read.attach(0);
                    c.uniform1i(et.uniforms.uVelocity, r), c.uniform1i(et.uniforms.uSource, r), c.uniform1f(et.uniforms.dt, t), c.uniform1f(et.uniforms.dissipation, e.VELOCITY_DISSIPATION), U(y.write.fbo), y.swap(), c.viewport(0, 0, m, v), l.supportLinearFiltering || c.uniform2f(et.uniforms.dyeTexelSize, 1 / m, 1 / v);
                    c.uniform1i(et.uniforms.uVelocity, y.read.attach(0)), c.uniform1i(et.uniforms.uSource, g.read.attach(1)), c.uniform1f(et.uniforms.dissipation, e.DENSITY_DISSIPATION), U(g.write.fbo), g.swap()
                }(.016),
                function(n) {
                    e.BLOOM && function(t, n) {
                        if (s.length < 2) return;
                        var r = n;
                        c.disable(c.BLEND), K.bind();
                        var i = e.BLOOM_THRESHOLD * e.BLOOM_SOFT_KNEE + 1e-4,
                            o = e.BLOOM_THRESHOLD - i,
                            a = 2 * i,
                            l = .25 / i;
                        c.uniform3f(K.uniforms.curve, o, a, l), c.uniform1f(K.uniforms.threshold, e.BLOOM_THRESHOLD), c.uniform1i(K.uniforms.uTexture, t.attach(0)), c.viewport(0, 0, r.width, r.height), U(r.fbo), Q.bind();
                        for (var u = 0; u < s.length; u++) {
                            var h = s[u];
                            c.uniform2f(Q.uniforms.texelSize, 1 / r.width, 1 / r.height), c.uniform1i(Q.uniforms.uTexture, r.attach(0)), c.viewport(0, 0, h.width, h.height), U(h.fbo), r = h
                        }
                        c.blendFunc(c.ONE, c.ONE), c.enable(c.BLEND);
                        for (var f = s.length - 2; f >= 0; f--) {
                            var d = s[f];
                            c.uniform2f(Q.uniforms.texelSize, 1 / r.width, 1 / r.height), c.uniform1i(Q.uniforms.uTexture, r.attach(0)), c.viewport(0, 0, d.width, d.height), U(d.fbo), r = d
                        }
                        c.disable(c.BLEND), $.bind(), c.uniform2f($.uniforms.texelSize, 1 / r.width, 1 / r.height), c.uniform1i($.uniforms.uTexture, r.attach(0)), c.uniform1f($.uniforms.intensity, e.BLOOM_INTENSITY), c.viewport(0, 0, n.width, n.height), U(n.fbo)
                    }(g.read, w);
                    null != n && e.TRANSPARENT ? c.disable(c.BLEND) : (c.blendFunc(c.ONE, c.ONE_MINUS_SRC_ALPHA), c.enable(c.BLEND));
                    var r = null == n ? c.drawingBufferWidth : m,
                        i = null == n ? c.drawingBufferHeight : v;
                    if (c.viewport(0, 0, r, i), !e.TRANSPARENT) {
                        W.bind();
                        var o = col;
                        c.uniform4f(W.uniforms.color, o.r / 255, o.g / 255, o.b / 255, 1), U(n)
                    }
                    null == n && e.TRANSPARENT && (X.bind(), c.uniform1f(X.uniforms.aspectRatio, t.width / t.height), U(null));
                    if (e.SHADING) {
                        var a = e.BLOOM ? J : Z;
                        if (a.bind(), c.uniform2f(a.uniforms.texelSize, 1 / r, 1 / i), c.uniform1i(a.uniforms.uTexture, g.read.attach(0)), e.BLOOM) {
                            c.uniform1i(a.uniforms.uBloom, w.attach(1)), c.uniform1i(a.uniforms.uDithering, G.attach(2));
                            var l = vt(G, r, i);
                            c.uniform2f(a.uniforms.ditherScale, l.x, l.y)
                        }
                    } else {
                        var u = e.BLOOM ? Y : q;
                        if (u.bind(), c.uniform1i(u.uniforms.uTexture, g.read.attach(0)), e.BLOOM) {
                            c.uniform1i(u.uniforms.uBloom, w.attach(1)), c.uniform1i(u.uniforms.uDithering, G.attach(2));
                            var h = vt(G, r, i);
                            c.uniform2f(u.uniforms.ditherScale, h.x, h.y)
                        }
                    }
                    U(n)
                }(null)
            }.bind(this),
            splat: function() { o.push(parseInt(20 * Math.random(), 10) + 5) }
        }
    }

    function _r(t, e) { if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function") }

    function br(t, e) {
        for (var n = 0; n < e.length; n++) {
            var r = e[n];
            r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(t, r.key, r)
        }
    }

    function xr(t, e) { return xr = Object.setPrototypeOf || function(t, e) { return t.__proto__ = e, t }, xr(t, e) }

    function wr(t) {
        var e = function() { if ("undefined" == typeof Reflect || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if ("function" == typeof Proxy) return true; try { return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], (function() {}))), true } catch (t) { return false } }();
        return function() {
            var n, r = Mr(t);
            if (e) {
                var i = Mr(this).constructor;
                n = Reflect.construct(r, arguments, i)
            } else n = r.apply(this, arguments);
            return Sr(this, n)
        }
    }

    function Sr(t, e) { if (e && ("object" === Er(e) || "function" == typeof e)) return e; if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined"); return function(t) { if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return t }(t) }

    function Mr(t) { return Mr = Object.setPrototypeOf ? Object.getPrototypeOf : function(t) { return t.__proto__ || Object.getPrototypeOf(t) }, Mr(t) }

    function Er(t) { return Er = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(t) { return typeof t } : function(t) { return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t }, Er(t) }
    var Tr = function(t, e, n, r) {
            var i, o = arguments.length,
                s = o < 3 ? e : null === r ? r = Object.getOwnPropertyDescriptor(e, n) : r;
            if ("object" === ("undefined" == typeof Reflect ? "undefined" : Er(Reflect)) && "function" == typeof Reflect.decorate) s = Reflect.decorate(t, e, n, r);
            else
                for (var a = t.length - 1; a >= 0; a--)(i = t[a]) && (s = (o < 3 ? i(s) : o > 3 ? i(e, n, s) : i(e, n)) || s);
            return o > 3 && s && Object.defineProperty(e, n, s), s
        },
        Hr = function(t) {
            ! function(t, e) {
                if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
                t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: true, configurable: true } }), e && xr(t, e)
            }(s, t);
            var e, n, i, o = wr(s);

            function s() {
                var t;
                return _r(this, s),
                (t = o.apply(this, arguments))._bg = false,
                t._canvas = false,
                t
            }
            return e = s, n = [{ key: "bg", get: function() { return this._bg }
            }, {
                key: "firstUpdated",
                value: function() {
                    var t = this;
                    r.a.load.loaded ? this._createBgOnPageShown() : r.a.load.on("", (function() { t._createBgOnPageShown() }))
                }
            }, {
                key: "_createBgOnPageShown",
                value: function() {
                    var t = this,
                        e = r.a.customPage;
                    e && e.onPageShown((function() { 
                        var e = Object(h.b)("canvas", { parent: t });
                        t._canvas = e,
                        e.addEventListener("webglcontextlost", (function() { }), false),
                        t._bg = yr(e),
                        setTimeout((function() { t._setEvents() }), 500)    
                    }))
                }
            }, {
                key: "_setEvents",
                value: function() {
                    if (this._bg) {
                        var t = Ft.getState().value.enabled;
                        var e = this;
                        if (t) {
                            pe.a.on("prerender", (function() { e._bg && e._bg.update() }))
                        }
                    }
                }
            }], n && br(e.prototype, n), i && br(e, i), s
        }(customHTML.a);
        Tr([Object(customHTML.b)("canvas-container")], Hr);
    }
]);


  }
}
</script>
