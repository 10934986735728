<template>
    <div>
        <div style="filter: invert(0.9);">
            <TextMarquee clone />
        </div>

        <router-link to="/" aria-current="page" class="navigation_link-brand w-inline-block">
            <!--<img class="drawn-logo" src="@/assets/img/with_text.svg" alt="Kasper Design Ltd.">-->
            <img class="drawn-logo" src="@/assets/img/with_text.png" alt="Kasper Design Ltd.">
        </router-link>
        <nav class="navigation_modal">
            <div class="navigation_top">
            <div class="nav_text-wrap">
                <p id="menu-text-1" class="navigation-text">
                    <span class="word"><span class="char">m</span>
                    <span class="char">e</span>
                    <span class="char">n</span>
                    <span class="char">u</span>
                    </span>
                </p>
                <p id="menu-text-2" class="navigation-text is-2">
                    <span class="word">
                        <span class="char">m</span>
                        <span class="char">e</span>
                        <span class="char">n</span>
                        <span class="char">u</span>
                    </span>
                </p>
            </div>
            <div class="navigation_top-cross-wrap">
                <div class="navigation_cross w-embed">
                    <svg width="inherit" height="inherit" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 4.5L8.99968 4.5" stroke="white" stroke-width="1.53605"></path>
                        <path d="M4.5 0.105469L4.5 9.10515" stroke="white" stroke-width="1.53605"></path>
                    </svg>
                </div>
            </div>
            </div>
            <div class="navigation_content">
                <router-link to="/" class="navigation-link w-inline-block">
                    <div class="navigation-link-text">home</div>
                    <div class="navigation_link-dash-wrap">
                        <div class="navigation_link-dash"></div>
                    </div>
                </router-link>
                <router-link to="/services" class="navigation-link w-inline-block">
                    <div class="navigation-link-text">services</div>
                    <div class="navigation_link-dash-wrap">
                        <div class="navigation_link-dash"></div>
                    </div>
                </router-link>
                <a href="https://portfolio.kasper.design" target="_blank" class="navigation-link w-inline-block">
                    <div class="navigation-link-text">work</div>
                    <div class="navigation_link-dash-wrap">
                        <div class="navigation_link-dash"></div>
                    </div>
                </a>
                <router-link to="/contact" id="navigation-project" class="navigation-link w-inline-block">
                    <div class="navigation-link-text">Get in touch</div>
                    <div id="navigation-dot" class="navigation_project-dot">
                        <div class="navigation_project-arrow w-embed">
                            <svg width="inherit" height="inherit" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.24805 3.49805L0.410815 3.49805" stroke="white" stroke-width="0.584113" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M3.91122 1.16797L6.25391 3.49546L3.91122 5.82296" stroke="white" stroke-width="0.584113" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                </router-link>
            </div>
        </nav>
        <div class="navigation_modal-touch">
            <div class="navigation_top-touch">
            <div class="nav_text-wrap-touch">
                <p class="navigation-text-touch">menu</p>
            </div>
            <div class="navigation_top-cross-wrap-touch">
                <div class="navigation_cross-touch w-embed">
                    <svg width="inherit" height="inherit" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 4.5L8.99968 4.5" stroke="white" stroke-width="1.53605"></path>
                        <path d="M4.5 0.105469L4.5 9.10515" stroke="white" stroke-width="1.53605"></path>
                    </svg>
                </div>
            </div>
            </div>
            <div class="navigation_content-touch">
            <router-link to="/" aria-current="page" class="navigation-link-touch w-inline-block">
                <div class="navigation-link-text-touch">home</div>
            </router-link>
            <router-link to="/services" class="navigation-link-touch w-inline-block">
                <div class="navigation-link-text-touch">services</div>
            </router-link>
            <a href="https://portfolio.kasper.design" target="_blank" class="navigation-link-touch w-inline-block">
                <div class="navigation-link-text-touch">work</div>
            </a>
            <router-link to="/contact" class="navigation-link-touch w-inline-block">
                <div class="navigation-link-text-touch">start a project</div>
                <div class="navigation_project-dot-touch">
                    <div class="navigation_project-arrow w-embed">
                        <svg width="inherit" height="inherit" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.24805 3.49805L0.410815 3.49805" stroke="white" stroke-width="0.584113" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M3.91122 1.16797L6.25391 3.49546L3.91122 5.82296" stroke="white" stroke-width="0.584113" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </div>
                </div>
            </router-link>
            </div>
        </div>
    </div>
</template>

<script>

import gsap from 'gsap';
import $ from 'jquery';
import scrolltrigger from '@/assets/js/ScrollTrigger.min.js';
import splittype from '@/assets/js/splittype.min.js';

import TextMarquee from '@/components/TextMarquee.vue'

gsap.registerPlugin(scrolltrigger);
gsap.registerPlugin(splittype);

export default {
    name: 'Header',
    components: {
         TextMarquee
      },
    props: {
        msg: String
    },
    mounted() {


        let typeSplit = new splittype(".text_btn", {
            types: "words, chars",
            tagName: "span"
        });
        
        let navSplit = new splittype(".navigation-text", {
            types: "words, chars",
            tagName: "span"
        });

        $(".navigation-link, .home-about_link, .home-review_link").each(function (index) {
            let myNavLink = $(this);
            let myNavDash = $(this).find(".navigation_link-dash");
            $(myNavLink).on("mouseenter mouseleave", function () {
            $(myNavDash).toggleClass("nav-active");
            $(myNavLink).toggleClass("nav-active");
            });
        });
        
        $("#navigation-project, .navigation-link.is-footer").each(function (index) {
            let myNavDot = $(this).find("#navigation-dot");
            $(this).on("mouseenter mouseleave", function () {
            $(myNavDot).toggleClass("nav-active");
            });
        });
        
        $(".nav_text-wrap").each(function (index) {
            let listOne = $(this).find("#menu-text-1 .char");
            let listTwo = $(this).find("#menu-text-2 .char");
            // Timeline
            let navHover = gsap.timeline({ paused: true });
            navHover.to(listOne, {
                translateY: "-1.5em",
                stagger: { each: 0.02 },
                ease: "power3.inOut",
                duration: 0.4
            });
            navHover.to(
                listTwo,
                {
                    translateY: "-1em",
                    stagger: { each: 0.02 },
                    ease: "power3.inOut",
                    duration: 0.4
                },
                0.1
            );
            $(".navigation_top-cross-wrap").on("mouseenter", function () {
                navHover.restart();
                $(".navigation_cross").addClass("hover-active");
            });
            $(".navigation_top-cross-wrap").on("mouseleave", function () {
                navHover.reverse();
                $(".navigation_cross").removeClass("hover-active");
            });
            $(".navigation_top-cross-wrap").on("click", function () {
                let nav = $(".navigation_modal");
                nav.toggleClass("is-closed");
            });
        });
        
        $(".navigation_modal-touch").on("click", function () {
            $(this).toggleClass("is-opened");
            $(".navigation_cross-touch").toggleClass("hover-active");
        });
    }
}
</script>

<style scoped lang="scss">

    /* NAVIGATION */
    .navigation_modal {
        position: fixed;
        left: auto;
        top: 3.4em;
        right: 40px;
        bottom: auto;
        z-index: 1000;
        overflow: hidden;
        width: 100%;
        height: 13.5em;
        max-width: 12.15em;
        padding: 0.42em;
        border: 1px solid #161617;
        background-color: #FFF;
        transition: height 0.3s;
    }
    .navigation_modal.is-closed {
        height: 3.35em;
    }
    .navigation_top {
        display: flex;
        padding: 0.3em 0 0.3em 0.56em;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #D90368;
        color: #fff;
    }
    .navigation_top.hover-active {
        background-color: #F75C03;
        color: #161617;
    }
    .navigation_top-cross-wrap {
        padding: 0.56em;
        cursor: pointer;
    }
    .navigation_cross {
        display: flex;
        width: 0.6em;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s;
    }
    .navigation_cross.hover-active {
        transform: rotate(45deg);
    }
    .navigation_content {
        display: flex;
        padding: 1.25em 0.56em;
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 1.04em;
    }
    .navigation-link {
        display: flex;
        align-items: center;
        grid-column-gap: 0.28em;
        transition: color 0.3s;
        font-family: 'Fjalla One', sans-serif;
        font-size: 1.2em;
        font-weight: 500;
        text-transform: uppercase;
    }
    .router-link-active,
    .navigation-link.nav-active {
        color: #D90368;
    }
    .navigation-link.is-footer {
        color: #fff;
    }
    .navigation-link.is-footer:hover {
        color: #F75C03;
    }
    .navigation-link.is-work_review {
        margin-top: 1.39em;
    }
    .navigation_link-dash {
        width: 0;
        height: 0.07em;
        background-color: #D90368;
        transition: width 0.3s;
    }
    .navigation_link-dash.nav-active {
        width: 0.7em;
    }
    .navigation_link-dash.background-color-yellow {
        background-color: #F75C03;
    }
    .navigation_link-dash.background-color-violet.is-mobile {
        height: 0.1em;
    }
    .navigation_project-dot {
        display: flex;
        width: 0.9em;
        height: 0.9em;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        border-radius: 100vw;
        background-color: #D90368;
        transition: 0.3s;
    }
    .navigation_project-dot.nav-active {
        background-color: #D90368;
        transform: rotate(-45deg);
    }
    .navigation_project-dot.is-footer {
        background-color: #EAEAEA;
    }
    .navigation_project-dot.is-footer.nav-active {
        background-color: #F75C03;
    }
    .navigation_project-dot.is-work_review {
        background-color: #EAEAEA;
        color: #161617;
    }
    .navigation_project-arrow {
        display: flex;
        width: 0.3em;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .navigation_project-arrow.is-footer,
    .navigation_project-arrow.is-work-review {
        color: #161617;
    }
    .navigation_link-dash-wrap {
        display: flex;
        width: 0.7em;
        justify-content: center;
        align-items: center;
    }
    .nav_text-wrap {
        position: relative;
        overflow: hidden;
    }
    .navigation-text {
        font-family: 'Fjalla One', sans-serif;
        font-size: 1.18em;
        line-height: 1;
        font-weight: 500;
        text-transform: uppercase;
    }
    .navigation-text.is-2 {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transform: translate(0, 1em);
    }

    .navigation_modal-touch {
        position: relative;
        z-index: 1000;
        display: none;
        overflow: hidden;
        width: 100%;
        height: 19.7em;
        max-width: 12.15em;
        padding: 0.42em;
        border: 1px solid #161617;
        background-color: #EAEAEA;
        transition: 0.3s;
    }
    .navigation_modal-touch.is-closed {
        height: 3.35em;
    }
    .navigation_top-touch {
        display: flex;
        padding: 0.3em 0 0.3em 0.56em;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #D90368;
        color: #fff;
    }
    .navigation_top-touch.hover-active {
        background-color: #F75C03;
        color: #161617;
    }
    .navigation_content-touch {
        display: flex;
        padding: 1.25em 0.56em;
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 1.04em;
    }
    .nav_text-wrap-touch {
        position: relative;
        overflow: hidden;
    }
    .navigation_top-cross-wrap-touch {
        padding: 0.56em;
        cursor: pointer;
    }
    .navigation-text-touch {
        font-family: 'Fjalla One', sans-serif;
        font-size: 1.18em;
        line-height: 1;
        font-weight: 500;
        text-transform: uppercase;
    }
    .navigation-text-touch.is-2 {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transform: translate(0, 1em);
    }
    .navigation_cross-touch {
        display: flex;
        width: 0.6em;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s;
    }
    .navigation_cross-touch.hover-active {
        transform: rotate(45deg);
    }
    .navigation-link-touch {
        display: flex;
        align-items: center;
        grid-column-gap: 0.28em;
        transition: color 0.3s;
        font-family: 'Fjalla One', sans-serif;
        font-size: 1.94em;
        font-weight: 500;
        text-transform: uppercase;
    }
    .navigation-link-touch.nav-active {
        color: #D90368;
    }
    .navigation_project-dot-touch {
        display: flex;
        width: 0.9em;
        height: 0.9em;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        border-radius: 100vw;
        background-color: #D90368;
        transform: rotate(-45deg);
        transition: 0.3s;
    }
    .navigation_project-dot-touch.nav-active {
        background-color: #D90368;
        transform: rotate(-45deg);
    }
    .navigation_link-brand {
        position: fixed;
        top: 2.6em;
        z-index: 1000;
        left: 40px;
    }


</style>
