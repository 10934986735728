<template>
    <a href="" target="_blank" class="button is-hero w-inline-block" style="opacity: 1;">
        <div class="btn_text-wrap">
            <div class="text_btn is-1">
                <span class="word"><span class="char">s</span>
                    <span class="char">e</span>
                    <span class="char">n</span>
                    <span class="char">d</span>
                    <span class="char">!</span>
                </span>
            </div>
            <div class="text_btn is-2">
                <span class="word">
                    <span class="char char-translate">s</span>
                    <span class="char char-translate">e</span>
                    <span class="char char-translate">n</span>
                    <span class="char char-translate">d</span>
                    <span class="char char-translate">!</span>
                </span>
            </div>
        </div>
    </a>
</template>


<script>

import gsap from 'gsap';
import $ from 'jquery';

// can potentially delete next 2 lines
import splittype from '@/assets/js/splittype.min.js';
gsap.registerPlugin(splittype);

export default {
    name: 'SendButton',
    methods: {
        buttonAnimation: function() {
            $(".button").each(function (index) {
            let listOne = $(this).find(".text_btn.is-1 .char");
            let listTwo = $(this).find(".text_btn.is-2 .char");
            // Timeline
            let buttonHover = gsap.timeline({ paused: true });
            buttonHover.to(listOne, {
                translateY: "-2rem",
                stagger: { each: 0.02 },
                ease: "power3.inOut",
                duration: 0.4
            });
            buttonHover.from(
                listTwo,
                {
                translateY: "2rem",
                stagger: { each: 0.02 },
                ease: "power3.inOut",
                duration: 0.4
                },
                0.1
            );
            $(this).on("mouseenter", function () {
                buttonHover.restart();
            });
            $(this).on("mouseleave", function () {
                buttonHover.reverse();
            });
            });
        },
    },
    mounted() {
        this.buttonAnimation();
    }
}
</script>