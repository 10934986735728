import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/ServicesView.vue')
  },/*
  {
    path: '/services/web-design',
    name: 'services-web-design',
    component: () => import('../views/services/WebView.vue')
  },
  {
    path: '/services/app-development',
    name: 'services-app-development',
    component: () => import('../views/services/AppView.vue')
  },
  {
    path: '/services/SEO',
    name: 'services-SEO',
    component: () => import('../views/services/SEOView.vue')
  },*/
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('../views/TermsAndConditions.vue')
  },
  {
    path: '/GDPR',
    name: 'GDPR',
    component: () => import('../views/GDPR.vue')
  },/*
  {
    path: '/work',
    name: 'work',
    component: () => import('../views/WorkView.vue')
  },*/
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
