<template>
    <div>
        <section class="section">
        <p>Scroll Down</p>
        </section>
        <section class="section-typing_text">
        <h2 class="text">This is some text</h2>
        <h2 class="typing_text-heading">
            <span class="typing_text"></span><span class="cursor">_</span>
        </h2>
        </section>
        <section class="section">
        <p>Scroll Up</p>
        </section>
    </div>
</template>

<script>

    import gsap from 'gsap';
    import scrolltrigger from '@/assets/js/ScrollTrigger.min.js';
    
    //gsap.registerPlugin(scrolltrigger);

    export default {
        name: 'CodeScrollType',
        mounted() {
        
            let textElem = document.querySelector('.text')

            gsap.to(".typing_text", {
            text: {
                value: textElem.innerText
            },
            scrollTrigger: {
                trigger: ".section-typing_text",
                pin: ".typing_text-heading",
                start: "center center",
                end: "center top",
                scrub: true,
                markers: true
            }
            });

        }
    }
</script>

<style scoped>
.typing_text-heading {
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
}

.section-typing_text {
  padding: 3rem;
}

.typing_text-heading {
  background: #2f2f2f;
  padding: 2rem;
  border: 1px solid red;
  width: 100%;
  max-width: 24rem;
  max-height: none !important;
  height: auto !important;
}

.section-typing_text .cursor {
  animation: cursorBlink 0.5s alternate infinite;
}

@keyframes cursorBlink {
  from {opacity: 0;}
  to {opacity: 100%;}
}

/* additional for presentation - not directly for the purpose */
body {
  margin: 0;
  background: #1f1f1f;
  color: white;
  font-family: sans-serif;
}

.section {
  height: 100vh;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
}
</style>